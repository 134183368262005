/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableExpandedRows } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { locale, addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import logoG from "../../public/images/logoG.png"; // Adjust the path as necessary
import { FileUpload } from "primereact/fileupload";
import saveAs from "file-saver";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { parse } from "date-fns";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { InputText } from "primereact/inputtext";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import React, { useEffect, useRef, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";

import "react-big-calendar/lib/css/react-big-calendar.css";

import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import pt from "date-fns/locale/pt";
const locales = {
  pt: pt,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface Turno {
  entrada: string;
  saida: string;
}

interface Motivo {
  id: number;
  motivo: string;
}

interface Despacho {
  id: number;
  despacho: string;
}

let flagChrono = -1;
let flagShift = -1;
let flagHours = -1;
/* @todo Used 'as any' for types here. Will fix in next version due to onSelectionChange event type issue. */
const User = () => {
  const { t } = useTranslation();

  addLocale("pt", {
    firstDayOfWeek: 1,
    dayNames: [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
  });

  addLocale("fr", {
    firstDayOfWeek: 1,
    dayNames: [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
    monthNames: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    monthNamesShort: [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Jun",
      "Jui",
      "Aoû",
      "Sep",
      "Oct",
      "Nov",
      "Déc",
    ],
    today: "Aujourd'hui",
    clear: "Effacer",
  });

  // Define a localidade do primereact com base no valor atual de linguagem do i18n
  locale(i18n.language);

  const [eventos, setEventos] = useState([]);
  const [pendentes, setPendentes] = useState<string>("");
  const [inicioRefeicao, setInicioRefeicao] = useState<string>("");
  const [fimRefeicao, setFimRefeicao] = useState<string>("");
  const [dias, setDias] = useState<string>("");
  const [majoracao, setDiasMajoracao] = useState<string>("");
  const [feriasTransitadas, setferiasTransitadas] = useState<string>("");
  const [gozados, setGozados] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [restantes, setRestantes] = useState<string>("");
  const restantesStyle = {
    color: parseFloat(restantes) < 0 ? "red" : "black",
  };
  const [diasFalta, setDiasFalta] = useState<string>("0");
  const [horasFalta, setHorasFalta] = useState<string>("0h 0min");
  const [diasFerias, setDiasFerias] = useState<string>("0");

  const [falta, setFalta] = useState<Falta | null>(null); // Exemplo de inicialização com null
  const [dates, setDates] = useState<Date[] | null>(null);
  const [listaFaltas, setFaltas] = useState<Falta[]>([]);
  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage

  useEffect(() => {
    getFaltas();
    fetchUpcomingEventsData2();
    fetchUpcomingEventsData3();
    fetchUpcomingEventsData4();
    fetchUpcomingEventsData5();
    fetchUpcomingEventsData6();
    fetchUpcomingEventsData7();
  }, []);

  const [vacationPeriods, setVacationPeriods] = useState([]);

  const getFaltas = async () => {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getFaltas",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );
        const data = await response.json();

        // Filter and map the vacation periods where motivo is "Férias"

        const fériasPeriods = data
          .filter(
            (vacation: { id_motivo: number; estado: string }) =>
              vacation.id_motivo == 1 &&
              (vacation.estado === "amarelo" || vacation.estado === "verde")
          )
          .map(
            (vacation: {
              dataInicio: any;
              horaInicio: any;
              dataFim: any;
              horaFim: any;
            }) => {
              return {
                start: `${vacation.dataInicio} ${vacation.horaInicio}`,
                end: `${vacation.dataFim} ${vacation.horaFim}`,
              };
            }
          );

        // Update the state with the new vacation periods
        setVacationPeriods(fériasPeriods);

        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data);
      }
    } catch (error) {
      console.error("Could not getFaltas", error);
    }
  };

  async function fetchUpcomingEventsData2() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getDias",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (
          String(data[0].inicio) == "00:00" &&
          String(data[0].fim) == "00:00"
        ) {
          setInicioRefeicao(t("NO_MEAL"));
        } else {
          setInicioRefeicao(String(data[0].inicio) + t("AT"));
          setFimRefeicao(String(data[0].fim));
        }
        setDias(String(data[0].total_dias));
        setDiasMajoracao(String(data[0].majoracao));
        setferiasTransitadas(String(data[0].ferias_transitadas));
        setUser(String(data[0].user));
        setGozados(String(data[0].dias_gozados));
        setPendentes(String(data[0].pendentes));
        let restantes =
          data[0].total_dias - data[0].dias_gozados - data[0].pendentes;
        setRestantes(String(restantes));
        //setUpcomingEventsData(response.data);
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  // Função para comparar dois horários
  function compareHorarios(a: SelectHourItem, b: SelectHourItem) {
    // Considera horários menores que "03:00" como maiores para fins de ordenação
    const isALessThanThree = a.value < "03:00";
    const isBLessThanThree = b.value < "03:00";

    if (isALessThanThree && !isBLessThanThree) {
      // Move 'a' para o fim se 'a' é menor que "03:00" e 'b' não é
      return 1;
    } else if (!isALessThanThree && isBLessThanThree) {
      // Move 'b' para o fim se 'b' é menor que "03:00" e 'a' não é
      return -1;
    }
    // Se ambos os horários são menores que "03:00" ou ambos maiores, compara normalmente
    return a.value.localeCompare(b.value);
  }

  const [turnos, setTurnos] = useState<Turno[]>([]);

  type SelectHourItem = {
    value: string;
    text: string;
  };

  const [selectedTurno, setSelectedTurno] = useState<Turno | null>(null);
  const [selectedTurno2, setSelectedTurno2] = useState<Turno | null>(null);

  const [SELECT_HOURS, setSelectHours1] = useState([]);

  const [SELECT_HOURS2, setSelectHours2] = useState([]);

  async function fetchUpcomingEventsData3() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTurnos",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setTurnos(
          data.map((dado: { id: number; entrada: any; saida: any }) => ({
            id: dado.id,
            entrada: dado.entrada,
            saida: dado.saida,
          }))
        );

        // Inicia com listas vazias para acumular os valores
        let newSelectHours1: SelectHourItem[] = [];
        let newSelectHours2: SelectHourItem[] = [];

        data.forEach((dado: { entrada: string; saida: string }) => {
          const novoItem = { value: dado.entrada, text: dado.entrada + " " };
          const novoItem2 = { value: dado.saida, text: dado.saida + " " };

          // Adiciona os novos itens à lista temporária se não existirem
          if (!newSelectHours1.some((item) => item.value === novoItem.value)) {
            newSelectHours1.push(novoItem);
          }
          if (!newSelectHours2.some((item) => item.value === novoItem2.value)) {
            newSelectHours2.push(novoItem2);
          }
        });

        // Ordena as listas temporárias
        newSelectHours1.sort(compareHorarios);
        newSelectHours2.sort(compareHorarios);

        // Atualiza o estado com as listas ordenadas
        setSelectHours1(newSelectHours1);
        setSelectHours2(newSelectHours2);
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [motivos, setMotivos] = useState<Motivo[]>([]);
  const [motivos2, setMotivos2] = useState<Motivo[]>([]);

  const [selectedMotivo, setSelectedMotivo] = useState<Motivo | null>(null);

  async function fetchUpcomingEventsData4() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getMotivos",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setMotivos(
          data
            .filter((dado: { id: number }) => dado.id !== 1) // Filtra para excluir o item com id: 1
            .map((dado: { id: number; motivo: string }) => ({
              id: dado.id,
              motivo: dado.motivo,
            }))
        );
        setMotivos2(
          data.map((dado: { id: number; motivo: string }) => ({
            id: dado.id,
            motivo: dado.motivo,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [despacho, setDespacho] = useState<Despacho[]>([]);
  const [selectedDespacho, setSelectedDespacho] = useState<Despacho | null>(
    null
  );

  async function fetchUpcomingEventsData5() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getDespachosSAFP",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setDespacho(
          data.slice(1).map((dado: { id: any; despacho: any }) => ({
            id: dado.id,
            despacho: dado.despacho,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  const [despachoHierarquia, setDespachoHierarquia] = useState<Despacho[]>([]);
  const [selectedDespachoHierarquia, setSelectedDespachoHierarquia] =
    useState<Despacho | null>(null);

  async function fetchUpcomingEventsData6() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getDespachosHierarquia ",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setDespachoHierarquia(
          data.slice(1).map((dado: { id: any; despacho: any }) => ({
            id: dado.id,
            despacho: dado.despacho,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  interface Feriado {
    dataFeriado: string;
  }
  interface Feriado2 {
    dataFeriado: string;
    descricao: string;
  }
  const [feriados, setFeriados] = useState<Feriado[]>([]);
  const [feriados2, setFeriados2] = useState<Feriado2[]>([]);

  async function fetchUpcomingEventsData7() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getFeriados ",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setFeriados(
          data.map((item: { dataFeriado: string }) => {
            // Dividir a string da data pelo caractere '/'
            const partes = item.dataFeriado.split("/");
            // Criar a data no formato desejado ('YYYY-MM-DD')
            return `${partes[2]}-${partes[1]}-${partes[0]}`;
          })
        );

        setFeriados2(
          data.map((item: { dataFeriado: string; descricao: string }) => {
            // Dividir a string da data pelo caractere '/'
            const partes = item.dataFeriado.split("/");
            // Criar a data no formato desejado ('YYYY-MM-DD') e incluir a descrição
            return {
              dataFeriado: `${partes[2]}-${partes[1]}-${partes[0]}`,
              descricao: item.descricao,
            };
          })
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  type Falta = {
    id_pedido: number;
    id_motivo: number;
    motivo: string;
    estado: string;
    descricao: string;
    Desp_Hierarquia: string;
    Inf_Desp_Hierarquia: string;
    Desp_SAFP: string;
    dataInicio: string;
    horaInicio: string;
    dataFim: string;
    horaFim: string;
    dias: string;
    dataPedido: string;
    ficheiro: number;
    data_safp: string;
    SAFP: string;
    chefe: string;
    data_auto: string;
    safp_observacoes: string;
    [key: string]: string | number;
  };

  type Ficheiro = {
    id: number;
    nome: string;
    [key: string]: string | number;
  };

  let emptyFicheiro: Ficheiro = {
    id: -1,
    nome: "",
  };

  const [procuraDialog, setProcuraDialog] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const [editDiasCompletosDialog, setEditDiasCompletosDialog] = useState(false);
  const [addDiasCompletosDialog, setAddDiasCompletosDialog] = useState(false);

  const [editHorasDialog, setEditHorasDialog] = useState(false);
  const [addHorasDialog, setAddHorasDialog] = useState(false);

  const [editFeriasDialog, setEditFeriasDialog] = useState(false);
  const [addFeriasDialog, setAddFeriasDialog] = useState(false);

  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    setErrorMessage("");
    setErrorMessageFile("");

    document.body.classList.add("no-scroll");
    setDates(null);
    setSelectedMotivo(null);
    setDescricao(undefined);
    setSelectedTurno(null);
    setSelectedTurno2(null);
    setSelectedDespacho(null);
    setSelectedDespachoHierarquia(null);
    setDateI(undefined);
    setDateII(undefined);
    setHoraI(undefined);
    setDateF(undefined);
    setDateFF(undefined);
    setHoraF(undefined);
    setShowUpload(true);
    setShowUpload2(false);
  };
  const openDiasCompletos = (rowData) => {
    handleVacationButtonClick();
    setSelectedRow(rowData.id_pedido);
    clean();
    setDiasFalta("0");
    setHeader(t("ADD_ABSENSE_DIAS"));
    setIcon("pi-calendar");
    setAddDiasCompletosDialog(true);
  };
  const openHoras = (rowData) => {
    handleVacationButtonClick();
    setSelectedRow(rowData.id_pedido);
    setHorasFalta("0h 0min");
    clean();
    setHeader(t("ADD_ABSENSE_HOURS"));
    setIcon("pi-calendar");
    setAddHorasDialog(true);
  };

  const openFerias = (rowData) => {
    handleVacationButtonClick();
    setSelectedRow(rowData.id_pedido);
    setDiasFerias("0");
    clean();
    setHeader(t("ADD_VACATION"));
    setIcon("pi-sun");
    setAddFeriasDialog(true);
  };

  const openProcuras = () => {
    handleVacationButtonClick();
    clean();
    setProcuraDialog(true);
  };

  // Helper function to convert "dd/mm/yyyy" to Date object
  const convertToDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    return new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
  };

  const editFalta = (rowData) => {
    document.body.classList.add("no-scroll");
    const motivo = {
      id: parseInt(rowData.id_motivo, 10), // Converte para inteiro
      motivo: rowData.motivo,
    };

    setSelectedMotivo(motivo);

    if (rowData?.dataInicio) {
      const dateParts = rowData.dataInicio.split("/"); // Divide a string em partes: dia, mês, ano
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`; // Formata para "mm/dd/yyyy"
      const dateObj = new Date(formattedDate); // Cria um objeto Date a partir da string formatada
      setDateI(dateObj); // Define a data de início como valor inicial do calendário
      setDateII(dateObj); // Define a data de início como valor inicial do calendário
    }
    if (rowData?.dataFim) {
      const dateParts = rowData.dataFim.split("/"); // Divide a string em partes: dia, mês, ano
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`; // Formata para "mm/dd/yyyy"
      const dateObj = new Date(formattedDate); // Cria um objeto Date a partir da string formatada
      setDateF(dateObj); // Define a data de início como valor inicial do calendário
      setDateFF(dateObj); // Define a data de início como valor inicial do calendário
    }

    if (rowData.horaInicio) {
      const horaInicioObj = parseTimeToDateTime(rowData.horaInicio); // Converte horaInicio para Date
      setHoraI(horaInicioObj); // Define a hora de início como valor inicial do calendário
    }
    if (rowData.horaFim) {
      const horaFimObj = parseTimeToDateTime(rowData.horaFim); // Converte horaFim para Date
      setHoraF(horaFimObj); // Define a hora de fim como valor inicial do calendário
    }
    setDescricao(rowData?.descricao);
    setFalta(rowData);
    handleVacationButtonClick();
    setIcon("pi-pencil");
    setSelectedRow(rowData.id_pedido);

    if (rowData.ficheiro == "0") {
      setShowUpload(true);
      setShowUpload2(false);
    } else if (rowData.ficheiro == "1") {
      setShowUpload2(true);
      setShowUpload(false);
    }

    setErrorMessage("");
    setErrorMessageFile("");

    if (rowData.id_motivo == "1") {
      setSelectedTurno(rowData.horaInicio);
      setSelectedTurno2(rowData.horaFim);
      setHeader(t("EDIT_VACATION"));
      setEditFeriasDialog(true);
    } else if (rowData.horaFim == "") {
      setHeader(t("EDIT_ABSENSE"));

      setDates([
        convertToDate(rowData?.dataInicio),
        convertToDate(rowData?.dataFim),
      ]);

      setEditDiasCompletosDialog(true);
    } else if (rowData.horaFim != "") {
      setHeader(t("EDIT_ABSENSE_HOURS"));
      setDateF(undefined); // Define a data de início como valor inicial do calendário
      setEditHorasDialog(true);
    }
  };

  // Função para converter uma string de hora no formato HH:mm para um objeto Date
  const parseTimeToDateTime = (timeStr: string): Date => {
    const today = new Date(); // Obtém a data atual para preencher a data, mas apenas a hora será utilizada
    const [hours, minutes] = timeStr.split(":").map(Number);
    today.setHours(hours);
    today.setMinutes(minutes);
    return today;
  };
  const hideEditDiasCompletos = () => {
    setEditDiasCompletosDialog(false);
    close();
  };
  const hideAddDiasCompletos = () => {
    setAddDiasCompletosDialog(false);
    close();
  };

  const hideProcuras = () => {
    setProcuraDialog(false);

    close();
  };

  const hideEditHoras = () => {
    setEditHorasDialog(false);
    close();
  };

  const hideAddHoras = () => {
    setAddHorasDialog(false);
    close();
  };

  const hideEditFerias = () => {
    setEditFeriasDialog(false);
    close();
  };

  const hideAddFerias = () => {
    setAddFeriasDialog(false);
    close();
  };

  const hideInfo = () => {
    setInfoDialog(false);
    close();
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
    setSelectedRow2(null); // Limpa a seleção de todas as linhas
  };

  const openInfo = (rowData) => {
    setFalta(rowData);
    document.body.classList.add("no-scroll");
    handleVacationButtonClick();
    setSelectedRow2(rowData.id_pedido);
    if (rowData.ficheiro == "1") setShowUpload2(true);
    else setShowUpload2(false);
    setInfoDialog(true);
  };

  const [IsProcuras, setIsProcuras] = useState(false);

  const procura = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsProcuras) return;
    setIsProcuras(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/getFaltas2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: JSON.stringify({
            motivo: selectedMotivo?.id || "",
            dataInicio: dateI ? formatDate(dateI) : "", // Check if dateI is defined before calling formatDate
            dataFim: dateF ? formatDate(dateF) : "", // Check if dateF is defined before calling formatDate
            autoChefia: selectedDespachoHierarquia?.id || "",
            decisao: selectedDespacho?.id || "",
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data);
        setFirst(0);
        hideProcuras();
        showInfoMessage(t("SEARCH_INFO"));
      } else {
        setErrorMessage(t("SEARCH_INFO_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("SEARCH_INFO_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsProcuras(false);
    }
  };

  const reset = () => {
    setFirst(0);
    getFaltas();
  };

  const [first, setFirst] = useState(0); // Estado para controlar a primeira linha visível
  const [rows, setRows] = useState(10); // Estado para controlar o número de linhas por página

  const onPageChange = (e) => {
    setFirst(e.first); // Atualiza o estado da primeira linha visível
    setRows(e.rows); // Atualiza o estado do número de linhas por página
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2 buttons-container ">
          <Button
            label={t("VACATION")}
            icon="pi pi-plus"
            className=" mr-4"
            onClick={openFerias}
            rounded
            style={{
              padding: "0.25rem 1rem",
              borderRadius: "20px", // Match the input field border radius
            }}
          />
          <Button
            label={t("DAYS")}
            icon="pi pi-plus"
            rounded
            className=" mr-2"
            onClick={openDiasCompletos}
            style={{
              padding: "0.25rem 1rem",
              borderRadius: "20px", // Match the input field border radius
            }}
          />
          <Button
            label={t("HOURS")}
            icon="pi pi-plus"
            className=" mr-2"
            onClick={openHoras}
            rounded
            style={{
              padding: "0.25rem 1rem",
              borderRadius: "20px", // Match the input field border radius
            }}
          />

          <Button
            label={t("SEARCH")}
            icon="pi pi-search
            "
            className=" mr-2 procurasbutton"
            onClick={openProcuras}
            rounded
          />

          <Button
            label={t("CLEAN")}
            icon="pi  pi-refresh"
            className=" mr-2 procurasbutton"
            onClick={reset}
            rounded
          />
          <Button
            label={t("SHOW_CALENDAR")}
            icon="pi pi-calendar"
            className="mr-2 procurasbutton"
            onClick={openCalendar}
            rounded
          />
        </div>
      </React.Fragment>
    );
  };

  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    if (feriados.length > 0 && listaFaltas.length >= 0) {
      // Aqui você pode formatar as faltas para eventos com os feriados já carregados
      const eventosFormatados = formatarFaltasParaEventos(listaFaltas);
      setEventos(eventosFormatados);
    }
  }, [feriados, listaFaltas]); // Reage às mudanças de 'feriados' e 'listaFaltas'

  const formatarFaltasParaEventos = (faltas) => {
    const eventos = [];
    faltas.forEach((falta) => {
      const { dataInicio, dataFim, motivo, horaFim, id_motivo } = falta;

      // Converter as datas de "dd/mm/aaaa" para "aaaa-mm-dd"
      const [diaInicio, mesInicio, anoInicio] = dataInicio.split("/");
      const [diaFim, mesFim, anoFim] = dataFim.split("/");

      // Criar objetos Date no formato ISO

      let inicio = new Date(`${anoInicio}-${mesInicio}-${diaInicio}`);
      inicio.setHours(0, 0, 0, 0); // Define a hora como 00:00:00.000
      const fim = new Date(`${anoFim}-${mesFim}-${diaFim}`);

      // Gerar um evento para cada dia no intervalo
      while (inicio <= fim) {
        if (
          inicio.getTime() === fim.getTime() &&
          horaFim &&
          horaFim < "03:31"
        ) {
          break;
        }

        if (
          inicio.getDay() !== 0 &&
          inicio.getDay() !== 6 &&
          !feriados.includes(formatDate(inicio))
        ) {
          eventos.push({
            title: `${motivo}`,
            start: new Date(inicio), // Data de início do evento
            end: new Date(inicio), // Data de fim do evento (mesmo dia)
            id_motivo: id_motivo,
          });
        }
        // Avançar para o próximo dia
        inicio.setDate(inicio.getDate() + 1);
      }
    });

    feriados2.forEach((feriado) => {
      const [anoInicio, mesInicio, diaInicio] = feriado.dataFeriado.split("-");

      // Criar objetos Date no formato ISO

      let inicio = new Date(`${anoInicio}-${mesInicio}-${diaInicio}`);
      inicio.setHours(0, 0, 0, 0); // Define a hora como 00:00:00.000

      // Adicionar feriado ao array de eventos
      eventos.push({
        title: feriado.descricao,
        start: new Date(inicio),
        end: new Date(inicio),
        id_motivo: null, // Sem motivo associado, mas pode ser adaptado
      });
    });

    return eventos;
  };

  const openCalendar = () => {
    setShowCalendar(true);
  };

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const leftToolbarTemplate2 = () => {
    return (
      <React.Fragment>
        <div className="my-2 toolbar">
          <div>
            {" "}
            {/* Adicionado alignItems: "center" */}
            <span
              style={{
                fontSize: "1.5em",
                padding: "0rem 5rem 0rem 0.7rem",
                // alignSelf: "flex-start", // Adicionado alignSelf: "flex-start" para alinhar o primeiro span à esquerda
              }}
            >
              <strong style={{ fontWeight: "bolder" }}>{user}</strong>
            </span>
          </div>
          <div className="toolbar-desktop">
            <span style={{ padding: "0 2rem" }}>
              <strong style={{ fontWeight: "bold" }}>
                {" "}
                {t("TOTAL_DAYS_VACATION")}:
              </strong>{" "}
              {dias}
            </span>

            <span style={{ padding: "0 2rem" }}>
              <strong style={{ fontWeight: "bold" }}>
                {" "}
                {t("FERIAS_TRANSITADAS")}:
              </strong>{" "}
              {feriasTransitadas}
            </span>

            <span style={{ padding: "0 2rem" }}>
              <strong style={{ fontWeight: "bold" }}>
                {t("INFO_MAJORACAO")}:
              </strong>{" "}
              {majoracao}
            </span>
            <span style={{ padding: "0 2rem" }}>
              <strong style={{ fontWeight: "bold" }}>{t("TAKEN")}:</strong>{" "}
              {gozados}
            </span>
            <span style={{ padding: "0 2rem" }}>
              <strong>{t("PENDING")}:</strong> {pendentes}
            </span>
            <span style={{ padding: "0 2rem" }}>
              <strong>{t("LEFT")}:</strong>{" "}
              <span style={restantesStyle}> {restantes}</span>
            </span>
            {/* <span style={{ padding: "0 0.5rem" }}>
              <strong>{t("MEAL")}:</strong> {inicioRefeicao} {fimRefeicao}
            </span> */}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const editarFalta = (rowData) => {
    if (
      (rowData.estado === "amarelo" &&
        rowData.Desp_SAFP === "" &&
        rowData.Desp_Hierarquia === "" &&
        rowData.id_motivo !== "1" &&
        rowData.horaInicio === "") ||
      (rowData.Desp_SAFP === "" &&
        rowData.Desp_Hierarquia === "" &&
        rowData.id_motivo !== "1" &&
        rowData.horaInicio !== "") ||
      (rowData.estado === "amarelo" &&
        rowData.Desp_SAFP === "" &&
        rowData.Desp_Hierarquia === "" &&
        rowData.id_motivo === "1")
    ) {
      return (
        <>
          <Button
            icon="pi pi-pencil"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => editFalta(rowData)}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const info = (rowData: Falta) => {
    return (
      <>
        <Button
          icon="pi pi-info-circle"
          size="small"
          rounded
          className="  pencil"
          style={{
            background: "none",
            color: "black",
            border: "1px solid rgb(231, 238, 248)",
          }}
          onClick={() => openInfo(rowData)}
        />
      </>
    );
  };

  const press = (rowData: Falta) => {
    if (
      rowData.Desp_SAFP !== "" &&
      rowData.Desp_Hierarquia !== "" &&
      rowData.id_despacho_safp !== "2"
    ) {
      return (
        <>
          <Button
            icon="pi pi-print          "
            size="small"
            rounded
            onClick={() =>
              handlePrint(
                rowData.dataInicio,
                rowData.dataFim,
                rowData.horaInicio,
                rowData.horaFim,
                rowData.descricao,
                rowData.motivo,
                rowData.dias,
                rowData.dataPedido,
                rowData.data_safp,
                rowData.SAFP,
                rowData.chefe,
                rowData.data_auto,
                rowData.Desp_Hierarquia,
                rowData.Inf_Desp_Hierarquia,
                rowData.Desp_SAFP,
                rowData.safp_observacoes,
                rowData.id_motivo
              )
            }
            className="  pencil"
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const handlePrint = (
    startDate,
    endDate,
    startHour,
    endHour,
    descricao,
    motivo,
    dias,
    dataPedido,
    dataSAFP,
    SAFP,
    chefe,
    data_auto,
    Desp_Hierarquia,
    Info_Hierarquia,
    Desp_SAFP,
    safp_observacoes,
    id_motivo
  ) => {
    // Crie uma nova janela para impressão

    const printWindow = window.open("", "_blank");
    let motivo2 = t("ABSENSE");

    if (id_motivo == 1) motivo2 = t("VACATION");
    if (endHour === "X") endHour = "";
    // Escreva o HTML para esta nova janela
    if (printWindow) {
      printWindow.document.title = "oi";
      printWindow.document.write(`
		   <html>
			 <head>
       <title>&nbsp;</title>
			 <style>
            // Estilos que você precisa apenas para a impressão
            body { font-family: Arial, sans-serif; font-size: 16pt; }
            .info-section { margin-bottom: 20px; }
            .info-section label { font-weight: bold; }
            .info-section span { margin-left: 5px; }
            .bold { font-weight: bold; }
            .small-text { font-size: 8pt; }
			.label { font-weight: bold; } // classe aplicada aos rótulos para negrito
     	    .value { font-weight: normal; }

			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.header img {
				width: 30%;
				height: auto;
			}
			.header h1 {
				margin: 0;
				padding: 0;
				font-size: 24pt; // Ajuste conforme necessário
			}

          </style>
			 </head>
			 <body>
			 <div class="header">
                    <h1>${motivo2} ${t("OF2")} ${user}</h1>
                    <img src=${logoG} alt="Logo" />

                </div>
			   <p><strong>${t("MOTIVE")}:</strong> ${motivo}</p>
			   <p><strong>${t("DESCRIPTION")}:</strong> ${descricao}</p>
			   <p><strong>${t("BEGIN_DATE")}:</strong> ${startDate}  </p>
			   <p><strong>${t("BEGIN_HOUR")}:</strong>  ${startHour}</p>
			   <p><strong>${t("END_DATE")}:</strong> ${endDate}  </p>
			   <p><strong>${t("END_HOUR")}:</strong>   ${endHour}</p>
			   <p><strong>${t("TIME")}:</strong>   ${dias}</p>
			   <p><strong>${t("REQUEST_DATE")}:</strong>   ${dataPedido}</p>


			    
			 <div class="info-section small-text">
			   <label>${t("NOTE")}</label> 
			 </div>
			 <span>------------------------------------------------------------------------------------------------------------------------------
			 </span>
			 <span> </span>
			 <div class="info-section">
					<label>${t("ORDER_HIERARCHY3")}:</label>
					<span class="value"> ${Desp_Hierarquia} </span>
			 </div>
			
			<div class="info-section">
			   <label>${t("INFO")}:</label>
			   <span class="value">${
           Info_Hierarquia
             ? Info_Hierarquia
             : "________________________________________________________________________"
         }</span>
			 
			 </div>

			   <div class="info-section">
			   <label>${t("RESPONSIBLE")}: </label> 
			   <span class="value">${
           chefe != null && chefe != "" && chefe !== "null"
             ? chefe
             : "___________________________"
         }</span>
			   
			 </div>

			 <div class="info-section">
 
			 <label>${t("DATE")}: </label> 
			 <span class="value">${
         data_auto != null && data_auto !== "null" && data_auto !== ""
           ? data_auto
           : "__/__/____"
       }</span>
		   </div>

			   <span>------------------------------------------------------------------------------------------------------------------------------
		  
			   <div class="info-section">
					<label>${t("ORDER_SAFP3")}:</label>
					<span class="value"> ${Desp_SAFP} </span>
			 </div>

			    

			   
			   <div class="info-section">
			   <label>${t("INFO")}:</label>
			   <span class="value">${
           safp_observacoes
             ? safp_observacoes
             : "________________________________________________________________________"
         }</span>
			 
			 </div>

			   <div class="info-section">
			   <label>${t("RESPONSIBLE")}: </label> 
			   <span class="value">${
           SAFP && SAFP !== "null" && SAFP !== ""
             ? SAFP
             : "___________________________"
         }</span>
 
			   </div>

			   <div class="info-section">
			   
			   <label>${t("DATE")}: </label> 
			   <span class="value">${
           dataSAFP && dataSAFP !== "null" ? dataSAFP : "__/__/____"
         }</span>
			   </div>

		 </body>
	   </html>
	 `);

      printWindow.document.close();
      printWindow.focus();

      // Aguarde o conteúdo da janela ser carregado para imprimir
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    }
  };

  const eliminarFalta = (rowData: Falta) => {
    if (
      rowData.estado === "amarelo" &&
      rowData.Desp_SAFP === "" &&
      rowData.Desp_Hierarquia === ""
    ) {
      return (
        <>
          <Button
            icon="pi pi-trash"
            rounded
            onClick={() => confirm1(rowData)}
            className="pencil"
            size="small"
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const procuraDialogFooter = (
    <>
      <Button
        label={t("SEARCH2")}
        icon="pi pi-search"
        text
        className="   procurasbutton"
        onClick={procura}
      />
    </>
  );

  const InfoFooter = <></>;

  const estadoTemplate = (rowData) => {
    if (rowData.estado === "amarelo") {
      return (
        <CircleIcon
          className="important-padding"
          sx={{
            color: "#fff3cd",
            backgroundColor: "#fff3cd", // Cor de fundo (bordas amarelo claro)
            borderRadius: "50%",
            border: "2px solid #ffc107", // Borda de contorno
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    } else if (rowData.estado === "vermelho") {
      return (
        <CircleIcon
          className="important-padding"
          sx={{
            color: "#f8d7da", // Cor do texto (branco)
            backgroundColor: "#f8d7da", // Cor de fundo (vermelho coral)
            borderRadius: "50%", // Bordas arredondadas
            border: "2px solid #ff6f6f", // Borda de contorno (laranja avermelhado)
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    } else if (rowData.estado === "verde") {
      return (
        <CircleIcon
          className="important-padding"
          sx={{
            color: "rgb(208 , 234 ,229 )", // Cor do texto (branco)
            backgroundColor: "rgb(208 , 234 ,229 )", // Cor do texto (branco)
            borderRadius: "50%", // Bordas arredondadas
            border: "2px solid rgb(99, 185, 170 )", // Borda de contorno (laranja avermelhado)
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    } else if (rowData.estado === "preto") {
      return (
        <CircleIcon
          className="important-padding"
          sx={{
            color: "rgb(187 , 188 ,190 )", // Cor do texto (branco)
            backgroundColor: "rgb(187 ,188 ,190 )", // Cor de fundo (vermelho coral)
            borderRadius: "50%", // Bordas arredondadas
            border: "2px solid rgb(31, 33, 40 )", // Borda de contorno (laranja avermelhado)
          }}
          style={{ width: "0.80em", height: "0.80em", marginTop: "0.2em" }}
        />
      );
    }
    return rowData.estado; // Retorna o valor original se não for "amarelo" ou "vermelho"
  };

  const ProcurasHeader = () => (
    <div>
      <i
        className="pi pi-search"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{t("SEARCH")}</label>
    </div>
  );

  const InfoHeader = () => (
    <div>
      <i
        className="pi pi-info-circle"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{t("ADDITIONAL_INFO")}</label>
    </div>
  );

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const confirm1 = (rowData) => {
    setSelectedRow(rowData.id_pedido);

    confirmDialog({
      message: t("DELETE_CONFIRMATION"),
    });
  };

  const [dateII, setDateII] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [dateFF, setDateFF] = useState<Date | undefined>(undefined); // Inicializado como undefined

  const [dateI, setDateI] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [dateF, setDateF] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [horaI, setHoraI] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [horaF, setHoraF] = useState<Date | undefined>(undefined); // Inicial  const [horaF, setHoraF] = useState(null);
  const [descricao, setDescricao] = useState<string | undefined>(undefined); // Estado para armazenar a descrição
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageFile, setErrorMessageFile] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRow2, setSelectedRow2] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    if (rowData.id_pedido === selectedRow) {
      return "highlight";
    } else if (rowData.id_pedido === selectedRow2) {
      return "highlight3";
    } else {
      return "";
    }
  };

  function isSaturdayBeforeTime(
    dateString: string | Date | undefined,
    timeString: string
  ) {
    const date = new Date(`${dateString}T${timeString}`);
    const dayOfWeek = date.getDay();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return dayOfWeek === 6 && (hour < 3 || (hour === 3 && minute < 31));
  }

  function verificarTurnoExistente(horaEntrada: any, horaSaida: any) {
    return turnos.some(
      (turno) => turno.entrada === horaEntrada && turno.saida === horaSaida
    );
  }

  useEffect(() => {
    setErrorMessage("");

    if (dateII && dateFF && selectedTurno && selectedTurno2) {
      const calculatedBusinessDays = calculateBusinessDays(
        moment(dateII).format("YYYY-MM-DD"),
        moment(dateFF).format("YYYY-MM-DD"),
        selectedTurno,
        selectedTurno2
      );

      setDiasFerias(String(calculatedBusinessDays));
    }
  }, [dateII, dateFF, selectedTurno, selectedTurno2]);

  useEffect(() => {
    setErrorMessage("");

    if (horaI && horaF)
      setHorasFalta(
        subtractTimes(
          moment(horaI).format("HH:mm"),
          moment(horaF).format("HH:mm")
        )
      );
  }, [horaI, horaF]);

  useEffect(() => {
    setErrorMessage("");

    if (dateI && dateF) {
      const calculatedBusinessDays = calculateBusinessDays2(
        moment(dateI).format("YYYY-MM-DD"),
        moment(dateF).format("YYYY-MM-DD")
      );

      setDiasFalta(String(calculatedBusinessDays));
    }
  }, [dateI, dateF]);

  function subtractTimes(time1: string, time2: string) {
    const toMinutes = (time: string) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    const isTimeInNoonBreak = (time: string) => {
      const minutes = toMinutes(time);
      return (
        minutes > toMinutes(inicioRefeicao.slice(0, 5)) &&
        minutes < toMinutes(fimRefeicao)
      );
    };

    // Converte os horários para minutos
    let time1Minutes = toMinutes(time1);
    let time2Minutes = toMinutes(time2);

    // Verifica se time2 está entre 00:00 e 03:30 e se time1 é maior que 03:30
    if (time2Minutes >= 0 && time2Minutes <= 210 && time1Minutes > 210) {
      // 210 minutos = 3 horas e 30 minutos
      // Adiciona 24 horas ao time2
      time2 = `${Math.floor((time2Minutes + 1440) / 60)}:${
        (time2Minutes + 1440) % 60
      }`;
      time2Minutes = toMinutes(time2);
    }

    // Calcula a diferença entre os tempos
    let difference = toMinutes(time2) - time1Minutes;

    if (
      time1Minutes <= toMinutes(inicioRefeicao.slice(0, 5)) &&
      time2Minutes >= toMinutes(fimRefeicao)
    )
      difference =
        difference -
        (toMinutes(fimRefeicao) - toMinutes(inicioRefeicao.slice(0, 5)));

    // Calcula horas e minutos
    const hours = Math.floor(difference / 60);
    const minutes = difference % 60;
    if (hours > 11) {
      setErrorMessage(t("HOUR_12_ERR"));
      flagHours = -1;
    } else flagHours = 1;

    // Verifica se time1 ou time2 estão dentro do horário de refeição
    if (isTimeInNoonBreak(time1) || isTimeInNoonBreak(time2)) {
      setErrorMessage(
        t("HOUR_ERR") + inicioRefeicao.slice(0, -3) + t("AND_1") + fimRefeicao
      );
      flagHours = -1;
    }

    return `${hours}h ${minutes}min`;
  }

  const calculateBusinessDays = (
    startDate: string,
    endDate: string,
    hourStart: string,
    hourEnd: string
  ): number => {
    if (!startDate || !endDate || !hourStart || !hourEnd) {
      return 0;
    }
    flagShift = 1;
    let businessDays: number = 0;
    let feriado = 0;

    if (feriados.includes(startDate)) {
      setErrorMessage(t("DATE_ERR_BEGIN"));
      flagChrono = -1;
      return 0;
    } else if (feriados.includes(endDate)) {
      if (hourEnd <= "03:30") feriado = -1;
      else {
        setErrorMessage(t("DATE_ERR_END"));
        flagChrono = -1;
        return 0;
      }
    }

    for (let i = 0; i < feriados.length; i++) {
      if (feriados[i] <= endDate && feriados[i] >= startDate) {
        feriado = feriado + 1;
      }
    }

    if (isSaturdayBeforeTime(endDate, hourEnd)) {
      businessDays = businessDays + 1;
    }

    const start: Date = parse(startDate, "yyyy-MM-dd", new Date());
    const end: Date = parse(endDate, "yyyy-MM-dd", new Date());
    let date1 = startDate + "T" + hourStart;
    let date2 = endDate + "T" + hourEnd;
    let fDate = new Date(date1);
    let lDate = new Date(date2);

    if (fDate > lDate) {
      setErrorMessage(t("SELECT_CHRONO"));
      flagChrono = -1;
      return 0;
    }

    flagChrono = 1;

    const currentDate: Date = new Date(start);

    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        businessDays++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (businessDays >= 1) {
      businessDays = businessDays - 1;
    }

    const hoursDifference = calculateHoursDifference(hourStart, hourEnd);

    if (hoursDifference >= 6 && hoursDifference < 12) {
      businessDays += 1;
    } else if (hoursDifference > 0 && hoursDifference < 6) {
      businessDays += 0.5;
    } else if (
      hoursDifference > -3 &&
      hoursDifference < 0 &&
      verificarTurnoExistente(hourStart, hourEnd)
    ) {
    } else {
      // A diferença de horas é 0 ou negativa ou maior que 12
      businessDays = 0;
      setErrorMessage(t("CHANGE_HOURS_ERR"));
      flagShift = -1;
      return businessDays;
    }

    if (isTimeBefore(hourEnd)) businessDays = businessDays - 1;

    flagShift = 1;
    setErrorMessage("");
    businessDays = businessDays - feriado;
    return businessDays;
  };

  const calculateHoursDifference = (start: string, end: string) => {
    const [startHour, startMinute] = start.split(":").map(Number);
    let [endHour, endMinute] = end.split(":").map(Number);
    if (endHour < 3) endHour = endHour + 24;

    return endHour + endMinute / 60 - (startHour + startMinute / 60);
  };

  const isTimeBefore = (targetTime: string) => {
    const comparisonTime = "03:31";
    const baseDate = new Date();

    // Split the target time and comparison time into hours and minutes
    const [targetHours, targetMinutes] = targetTime.split(":").map(Number);
    const [comparisonHours, comparisonMinutes] = comparisonTime
      .split(":")
      .map(Number);

    // Set the hours and minutes for the target and comparison dates
    const targetDate = new Date(baseDate);
    targetDate.setHours(targetHours, targetMinutes);

    const comparisonDate = new Date(baseDate);
    comparisonDate.setHours(comparisonHours, comparisonMinutes);

    // Return true if targetDate is before comparisonDate
    return targetDate < comparisonDate;
  };

  /*  function calculateDaysBetweenDates(
    date1: string | number | Date,
    date2: string | number | Date
  ) {
    if (!date1 || !date2) {
      return 0;
    }
    // Convert both dates to JavaScript Date objects
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    if (startDate > endDate) {
      setErrorMessage("Selecione datas por ordem cronológica.");
      flagChrono = -1;
      return 0;
    }
    flagChrono = 1;
    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(endDate - startDate);

    // Convert milliseconds to days and add 1
    return Math.ceil(diffInMs / (1000 * 60 * 60 * 24)) + 1;
  } */

  const calculateBusinessDays2 = (
    startDate: string,
    endDate: string
  ): number => {
    if (!startDate || !endDate) {
      return 0;
    }
    let feriado = 0;

    if (feriados.includes(startDate)) {
      setErrorMessage(t("DATE_ERR_BEGIN"));
      flagChrono = -1;

      return 0;
    } else if (feriados.includes(endDate)) {
      setErrorMessage(t("DATE_ERR_END"));
      flagChrono = -1;

      return 0;
    }

    for (let i = 0; i < feriados.length; i++) {
      if (feriados[i] <= endDate && feriados[i] >= startDate) {
        feriado = feriado + 1;
      }
    }

    const start: Date = parse(startDate, "yyyy-MM-dd", new Date());
    const end: Date = parse(endDate, "yyyy-MM-dd", new Date());
    let date1 = startDate;
    let date2 = endDate;
    let fDate = new Date(date1);
    let lDate = new Date(date2);

    if (fDate > lDate) {
      setErrorMessage(t("SELECT_CHRONO"));
      flagChrono = -1;
      return 0;
    }
    flagChrono = 1;

    let businessDays: number = 0;
    const currentDate: Date = new Date(start);

    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        businessDays++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setErrorMessage("");
    businessDays = businessDays - feriado;
    return businessDays;
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.files[0];

    if (file == undefined) {
      setErrorMessageFile(t("FILE_TOO_BIG"));
    } else {
      setErrorMessageFile("");
      setSelectedFile(file); // Armazena o ficheiro na variável global
    }
  };

  const [showUpload, setShowUpload] = useState(false);
  const [showUpload2, setShowUpload2] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if isoString is undefined or null
    const date = new Date(dateString);

    // Get the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0"); // Pad single-digit days with a leading zero

    // Return formatted date
    return `${year}-${month}-${day}`;
  };

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "success",
      icon: "pi pi-check",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const showNiceMessage2 = (message) => {
    toast.current?.show({
      severity: "success",
      icon: "pi pi-trash",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const showInfoMessage = (message) => {
    toast.current?.show({
      severity: import.meta.env.VITE_API_SEVERITY,
      icon: "pi pi-search",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 1500,
    });
  };

  function isWeekend(dateString: string | number | Date) {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();

    return dayOfWeek === 0 || dayOfWeek === 6;
  }

  const [IsAddFaltaDiasCompletos, setIsAddFaltaDiasCompletos] = useState(false);

  const addFaltaDiasCompletos = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAddFaltaDiasCompletos) return;
    setIsAddFaltaDiasCompletos(true);

    const formData = new FormData();

    // Append the file and other form fields to the FormData
    let _ficheiro = "0";

    if (selectedFile) {
      _ficheiro = "1";
      formData.append("file", selectedFile);
      formData.append("tem_ficheiro", _ficheiro);
    } else formData.append("tem_ficheiro", _ficheiro);

    if (selectedMotivo == null || selectedMotivo == undefined) {
      setErrorMessage(t("SELECT_MOTIVE"));
      setIsAddFaltaDiasCompletos(false); // Reseta o estado isDeleting para false
      return;
    }

    if (selectedMotivo.id == "9" && !selectedFile) {
      setIsAddFaltaDiasCompletos(false);
      setErrorMessage(t("ATTACH_FILE"));
      return;
    }
    if (
      selectedMotivo.id == "7" &&
      (descricao == undefined || descricao.trim().length == 0)
    ) {
      setIsAddFaltaDiasCompletos(false);
      setErrorMessage(t("FILL_DESCRIPTION"));
      return;
    }

    if (dateI == null || dateI == undefined) {
      setErrorMessage(t("SELECT_BEGIN_DATE"));
      setIsAddFaltaDiasCompletos(false); // Reseta o estado isDeleting para false
      return;
    }

    if (dateF == null || dateF == undefined) {
      setErrorMessage(t("SELECT_END_DATE"));
      setIsAddFaltaDiasCompletos(false); // Reseta o estado isDeleting para false
      return;
    }

    if (flagChrono === -1) {
      setIsAddFaltaDiasCompletos(false); // Reseta o estado isDeleting para false
      return;
    }

    if (isWeekend(dateI)) {
      setIsAddFaltaDiasCompletos(false); // Reseta o estado isDeleting para false
      setErrorMessage(t("DATE_ERR2_BEGIN"));
      return;
    }

    if (isWeekend(dateF)) {
      setIsAddFaltaDiasCompletos(false); // Reseta o estado isDeleting para false
      setErrorMessage(t("DATE_ERR2_END"));
      return;
    }

    formData.append("dataInicio", formatDate(dateI));
    formData.append("dataFim", formatDate(dateF));
    formData.append("motivo", selectedMotivo.id);

    if (descricao == undefined || descricao.length == 0)
      formData.append("descricao", "");
    else if (descricao.length > 0) {
      formData.append("descricao", descricao.substring(0, 100));
    } else {
      setErrorMessage(t("GENERAL_ERR"));
      setIsAddFaltaDiasCompletos(false); // Reseta o estado isDeleting para false
      return null;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/addFaltaDiasCompletos",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getFaltas();
        hideAddDiasCompletos();
        showNiceMessage(t("ADD_ABSENSE_INFO"));
      } else {
        setErrorMessage(t("ABSENSE_ADD_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("ABSENSE_ADD_ERR"));
      console.error("An error occurred:", error);
    } finally {
      // Código que será executado independentemente do resultado do bloco try-catch
      setIsAddFaltaDiasCompletos(false); // Reseta o estado isDeleting para false
      setSelectedFile(null);
    }
  };

  const [IsEditFaltaDiasCompletos, setIsEditFaltaDiasCompletos] =
    useState(false);

  const editFaltaDiasCompletos = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsEditFaltaDiasCompletos) return;
    setIsEditFaltaDiasCompletos(true);

    const formData = new FormData();

    // Append the file and other form fields to the FormData
    let _ficheiro = "0";

    if (selectedFile) {
      _ficheiro = "1";
      formData.append("file", selectedFile);
      formData.append("tem_ficheiro", _ficheiro);
    } else formData.append("tem_ficheiro", _ficheiro);

    if (selectedMotivo == null || selectedMotivo == undefined) {
      setErrorMessage(t("SELECT_MOTIVE"));
      setIsEditFaltaDiasCompletos(false);
      return;
    }

    if (selectedMotivo.id == "9" && !selectedFile) {
      setIsEditFaltaDiasCompletos(false);
      setErrorMessage(t("ATTACH_FILE"));
      return;
    }
    if (
      selectedMotivo.id == "7" &&
      (descricao == undefined || descricao.trim().length == 0)
    ) {
      setIsEditFaltaDiasCompletos(false);
      setErrorMessage(t("FILL_DESCRIPTION"));
      return;
    }

    if (dateI == null || dateI == undefined) {
      setErrorMessage(t("SELECT_BEGIN_DATE"));
      setIsEditFaltaDiasCompletos(false);
      return;
    }

    if (dateF == null || dateF == undefined) {
      setIsEditFaltaDiasCompletos(false);
      setErrorMessage(t("SELECT_END_DATE"));
      return;
    }

    if (flagChrono === -1) {
      setIsEditFaltaDiasCompletos(false);
      return;
    }

    if (isWeekend(dateI)) {
      setIsEditFaltaDiasCompletos(false);
      setErrorMessage(t("DATE_ERR2_BEGIN"));
      return;
    }

    if (isWeekend(dateF)) {
      setIsEditFaltaDiasCompletos(false);
      setErrorMessage(t("DATE_ERR2_END"));
      return;
    }

    if (isNaN(parseInt(selectedRow, 10))) {
      setIsEditFaltaDiasCompletos(false);
      setErrorMessage("Something wrong with ID");
      return;
    }

    formData.append("id_pedido", selectedRow);
    formData.append("dataInicio", formatDate(dateI));
    formData.append("dataFim", formatDate(dateF));
    formData.append("motivo", selectedMotivo.id);

    if (descricao == undefined || descricao.length == 0)
      formData.append("descricao", "");
    else if (descricao.length > 0) {
      formData.append("descricao", descricao.substring(0, 100));
    } else {
      setIsEditFaltaDiasCompletos(false);
      setErrorMessage(t("GENERAL_ERR"));
      return null;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/editFaltaDiasCompletos",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getFaltas();
        hideEditDiasCompletos();
        showNiceMessage(t("EDIT_ABSENSE_INFO"));
      } else {
        setErrorMessage(t("EDIT_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("EDIT_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsEditFaltaDiasCompletos(false);
      setSelectedFile(null);
    }
  };

  const doesNewVacationOverlap = (
    newVacationStartStr: {
      split: (arg0: string) => {
        (): any;
        new (): any;
        map: { (arg0: (num: any) => number): [any, any, any]; new (): any };
      };
    },
    newVacationEndStr: {
      split: (arg0: string) => {
        (): any;
        new (): any;
        map: { (arg0: (num: any) => number): [any, any, any]; new (): any };
      };
    },
    hourStartStr: {
      split: (arg0: string) => {
        (): any;
        new (): any;
        map: { (arg0: (num: any) => number): [any, any]; new (): any };
      };
    },
    hourEndStr: {
      split: (arg0: string) => {
        (): any;
        new (): any;
        map: { (arg0: (num: any) => number): [any, any]; new (): any };
      };
    },
    existingVacations: any[]
  ) => {
    const newStart = parseISODateTime(newVacationStartStr, hourStartStr);
    const newEnd = parseISODateTime(newVacationEndStr, hourEndStr);

    return existingVacations.some(
      (vacation: {
        start: { split: (arg0: string) => [any, any] };
        end: { split: (arg0: string) => [any, any] };
      }) => {
        const existingStart = parseExistingDateTime(vacation.start);
        const existingEnd = parseExistingDateTime(vacation.end);
        return newStart < existingEnd && newEnd > existingStart;
      }
    );
  };

  const parseISODateTime = (
    dateStr: {
      split: (arg0: string) => {
        (): any;
        new (): any;
        map: { (arg0: (num: any) => number): [any, any, any]; new (): any };
      };
    },
    timeStr: {
      split: (arg0: string) => {
        (): any;
        new (): any;
        map: { (arg0: (num: any) => number): [any, any]; new (): any };
      };
    }
  ) => {
    const [year, month, day] = dateStr
      .split("-")
      .map((num) => parseInt(num, 10));
    const [hours, minutes] = timeStr.split(":").map((num) => parseInt(num, 10));
    return new Date(year, month - 1, day, hours, minutes);
  };

  const parseExistingDateTime = (dateTimeStr: {
    split: (arg0: string) => [any, any];
  }) => {
    // Assuming the format is 'DD/MM/YYYY HH:mm'
    const [date, time] = dateTimeStr.split(" ");
    const [day, month, year] = date
      .split("/")
      .map((num: string) => parseInt(num, 10));
    const [hours, minutes] = time
      .split(":")
      .map((num: string) => parseInt(num, 10));
    return new Date(year, month - 1, day, hours, minutes);
  };

  const [IsAddFerias, setIsAddFerias] = useState(false);

  const addFerias = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAddFerias) return;
    setIsAddFerias(true);

    const formData = new FormData();

    if (dateII == null || dateII == undefined) {
      setErrorMessage(t("SELECT_BEGIN_DATE"));
      setIsAddFerias(false);
      return;
    }

    if (selectedTurno == null || selectedTurno == undefined) {
      setIsAddFerias(false);
      setErrorMessage(t("SELECT_BEGIN_HOUR"));
      return;
    }

    if (dateFF == null || dateFF == undefined) {
      setIsAddFerias(false);
      setErrorMessage(t("SELECT_END_DATE"));
      return;
    }

    if (selectedTurno2 == null || selectedTurno2 == undefined) {
      setIsAddFerias(false);
      setErrorMessage(t("SELECT_END_HOUR"));
      return;
    }

    // Obter os anos diretamente dos objetos Date
    const anoII = dateII.getFullYear(); // Ano da data de início
    const anoFF = dateFF.getFullYear(); // Ano da data de fim

    // Comparar os anos
    if (anoII !== anoFF) {
      setErrorMessage(t("START_AND_END_YEAR_MUST_BE_SAME")); // Mensagem de erro
      setIsAddFerias(false);
      return;
    }

    if (flagChrono === -1 || flagShift === -1) {
      setIsAddFerias(false);
      return;
    }

    if (parseFloat(pendentes) + parseFloat(diasFerias) > 90) {
      setIsAddFerias(false);
      setErrorMessage(t("VACATION_LIMIT"));
      return;
    }

    if (!verificarTurnoExistente(selectedTurno, selectedTurno2)) {
      setIsAddFerias(false);
      setErrorMessage(t("SHIFT_ERR"));
      return;
    }

    if (isWeekend(formatDate(dateII))) {
      setIsAddFerias(false);
      setErrorMessage(t("DATE_ERR2_BEGIN"));
      return;
    }

    if (
      isWeekend(formatDate(dateFF)) &&
      !isSaturdayBeforeTime(formatDate(dateFF), selectedTurno2)
    ) {
      setIsAddFerias(false);
      setErrorMessage(t("DATE_ERR2_END"));
      return;
    }

    if (
      doesNewVacationOverlap(
        formatDate(dateII),
        formatDate(dateFF),
        selectedTurno,
        selectedTurno2,
        vacationPeriods
      )
    ) {
      setIsAddFerias(false);
      setErrorMessage(t("VACATION_ERR"));
      return;
    }

    formData.append("dataInicio", formatDate(dateII));
    formData.append("horaInicio", selectedTurno);
    formData.append("dataFim", formatDate(dateFF));
    formData.append("horaFim", selectedTurno2);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/addFerias",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getFaltas();
        fetchUpcomingEventsData2();
        hideAddFerias();
        showNiceMessage(t("ADD_VACATION_INFO"));
      } else {
        setErrorMessage(t("VACATION_ERR_DELETE"));
      }
    } catch (error) {
      setErrorMessage(t("VACATION_ERR_DELETE"));
      console.error("An error occurred:", error);
    } finally {
      setIsAddFerias(false);
    }
  };

  const [IsEditFerias, setIsEditFerias] = useState(false);

  const editFerias = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsEditFerias) return;
    setIsEditFerias(true);

    const formData = new FormData();

    if (isNaN(parseInt(selectedRow, 10))) {
      setIsEditFerias(false);
      setErrorMessage("Something wrong with ID");
      return;
    }

    if (dateII == null || dateII == undefined) {
      setIsEditFerias(false);
      setErrorMessage(t("SELECT_BEGIN_DATE"));
      return;
    }

    if (selectedTurno == null || selectedTurno == undefined) {
      setIsEditFerias(false);
      setErrorMessage(t("SELECT_BEGIN_HOUR"));
      return;
    }

    if (dateFF == null || dateFF == undefined) {
      setIsEditFerias(false);
      setErrorMessage(t("SELECT_END_DATE"));
      return;
    }

    if (selectedTurno2 == null || selectedTurno2 == undefined) {
      setIsEditFerias(false);
      setErrorMessage(t("SELECT_END_HOUR"));
      return;
    }

    // Obter os anos diretamente dos objetos Date
    const anoII = dateII.getFullYear(); // Ano da data de início
    const anoFF = dateFF.getFullYear(); // Ano da data de fim

    // Comparar os anos
    if (anoII !== anoFF) {
      setErrorMessage(t("START_AND_END_YEAR_MUST_BE_SAME")); // Mensagem de erro
      setIsEditFerias(false);
      return;
    }

    if (flagChrono === -1 || flagShift === -1) {
      setIsEditFerias(false);
      return;
    }

    if (parseFloat(pendentes) + parseFloat(diasFerias) > 90) {
      setIsEditFerias(false);
      setErrorMessage(t("VACATION_LIMIT"));
      return;
    }

    if (!verificarTurnoExistente(selectedTurno, selectedTurno2)) {
      setIsEditFerias(false);
      setErrorMessage(t("SHIFT_ERR"));
      return;
    }

    if (isWeekend(formatDate(dateII))) {
      setIsEditFerias(false);
      setErrorMessage(t("DATE_ERR2_BEGIN"));
      return;
    }

    if (
      isWeekend(formatDate(dateFF)) &&
      !isSaturdayBeforeTime(formatDate(dateFF), selectedTurno2)
    ) {
      setIsEditFerias(false);
      setErrorMessage(t("DATE_ERR2_END"));
      return;
    }

    if (
      doesNewVacationOverlap(
        formatDate(dateII),
        formatDate(dateFF),
        selectedTurno,
        selectedTurno2,
        vacationPeriods
      )
    ) {
      setIsEditFerias(false);
      setErrorMessage(t("VACATION_ERR"));
      return;
    }

    formData.append("id_pedido", selectedRow);
    formData.append("dataInicio", formatDate(dateII));
    formData.append("horaInicio", selectedTurno);
    formData.append("dataFim", formatDate(dateFF));
    formData.append("horaFim", selectedTurno2);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/editFerias",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getFaltas();
        fetchUpcomingEventsData2();
        hideEditFerias();
        showNiceMessage(t("EDIT_VACATION_INFO"));
      } else {
        setErrorMessage(t("EDIT_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("EDIT_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsEditFerias(false);
    }
  };

  function isLater(time1: any, time2: any) {
    // Create Date objects for the current day with the specified times
    const dateTime1 = new Date(`1970-01-01T${time1}:00`);
    const dateTime2 = new Date(`1970-01-01T${time2}:00`);

    // Compare the two times
    return dateTime2 > dateTime1;
  }
  function checkTimes(string1: string, string2: string) {
    const toMinutes = (time: string) => {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    };

    // Converte as strings para minutos
    const string1Minutes = toMinutes(string1);
    const string2Minutes = toMinutes(string2);

    // Verifica se string1 é superior a 07:00 (420 minutos) e string2 inferior a 03:30 (210 minutos)
    const isString1After7 = string1Minutes > 420;
    const isString2Before330 = string2Minutes < 210;

    return isString1After7 && isString2Before330;
  }

  const [IsAddFaltaHoras, setIsAddFaltaHoras] = useState(false);

  const addFaltaHoras = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAddFaltaHoras) return;
    setIsAddFaltaHoras(true);

    const formData = new FormData();

    // Append the file and other form fields to the FormData
    let _ficheiro = "0";

    if (selectedFile) {
      _ficheiro = "1";
      formData.append("file", selectedFile);
      formData.append("tem_ficheiro", _ficheiro);
    } else formData.append("tem_ficheiro", _ficheiro);

    if (selectedMotivo == null || selectedMotivo == undefined) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("SELECT_MOTIVE"));
      return;
    }

    if (selectedMotivo.id == "9" && !selectedFile) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("ATTACH_FILE"));
      return;
    }
    if (
      selectedMotivo.id == "7" &&
      (descricao == undefined || descricao.trim().length == 0)
    ) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("FILL_DESCRIPTION"));
      return;
    }

    if (dateI == null || dateI == undefined) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("SELECT_BEGIN_DATE"));
      return;
    }

    if (horaI == null || horaI == undefined) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("SELECT_BEGIN_HOUR"));
      return;
    }

    if (horaF == null || horaF == undefined) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("SELECT_END_HOUR"));
      return;
    }

    let horaInicial = horaI?.toLocaleTimeString("pt-PT", {
      hour: "2-digit",
      minute: "2-digit",
    });

    let horaFinal = horaF?.toLocaleTimeString("pt-PT", {
      hour: "2-digit",
      minute: "2-digit",
    });

    if (
      !isLater(horaInicial, horaFinal) &&
      !checkTimes(horaInicial, horaFinal)
    ) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("SELECT_CHRONO_HOURS"));
      return;
    }

    if (flagHours === -1) {
      setIsAddFaltaHoras(false);
      return;
    }

    if (
      isWeekend(formatDate(dateI)) &&
      !isSaturdayBeforeTime(formatDate(dateI), horaFinal)
    ) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("ERR_DATE_OR_HOUR"));
      return;
    }

    if (
      isWeekend(formatDate(dateI)) &&
      !isSaturdayBeforeTime(formatDate(dateI), horaInicial)
    ) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("SELECT_BEGIN_CORRECT_HOUR"));
      return;
    }

    if (
      feriados.includes(formatDate(dateI)) &&
      (horaInicial > "03:30" || horaFinal > "03:30")
    ) {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("HOLYDAY_ERR"));
      return;
    }

    formData.append("dataInicio", formatDate(dateI));
    formData.append("horaInicio", horaInicial);
    formData.append("horaFim", horaFinal);
    formData.append("motivo", selectedMotivo.id);

    if (descricao == undefined || descricao.length == 0)
      formData.append("descricao", "");
    else if (descricao.length > 0) {
      formData.append("descricao", descricao.substring(0, 100));
    } else {
      setIsAddFaltaHoras(false);
      setErrorMessage(t("GENERAL_ERR"));
      return null;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/addFaltaHoras",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getFaltas();
        hideAddHoras();
        showNiceMessage(t("ADD_ABSENSE_INFO"));
      } else {
        setErrorMessage(t("ABSENSE_ADD_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("ABSENSE_ADD_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsAddFaltaHoras(false);
      setSelectedFile(null);
    }
  };

  const [IsEditFaltaHoras, setIsEditFaltaHoras] = useState(false);

  const editFaltaHoras = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsEditFaltaHoras) return;
    setIsEditFaltaHoras(true);

    const formData = new FormData();

    if (isNaN(parseInt(selectedRow, 10))) {
      setIsEditFaltaHoras(false);
      setErrorMessage("Something wrong with ID");
      return;
    }

    // Append the file and other form fields to the FormData
    let _ficheiro = "0";

    if (selectedFile) {
      _ficheiro = "1";
      formData.append("file", selectedFile);
      formData.append("tem_ficheiro", _ficheiro);
    } else formData.append("tem_ficheiro", _ficheiro);

    if (selectedMotivo == null || selectedMotivo == undefined) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("SELECT_MOTIVE"));
      return;
    }

    if (selectedMotivo.id == "9" && !selectedFile) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("ATTACH_FILE"));
      return;
    }
    if (
      selectedMotivo.id == "7" &&
      (descricao == undefined || descricao.trim().length == 0)
    ) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("FILL_DESCRIPTION"));
      return;
    }

    if (dateI == null || dateI == undefined) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("SELECT_BEGIN_DATE"));
      return;
    }

    if (horaI == null || horaI == undefined) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("SELECT_BEGIN_HOUR"));
      return;
    }

    if (horaF == null || horaF == undefined) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("SELECT_END_HOUR"));
      return;
    }

    let horaInicial = horaI?.toLocaleTimeString("pt-PT", {
      hour: "2-digit",
      minute: "2-digit",
    });

    let horaFinal = horaF?.toLocaleTimeString("pt-PT", {
      hour: "2-digit",
      minute: "2-digit",
    });

    if (
      !isLater(horaInicial, horaFinal) &&
      !checkTimes(horaInicial, horaFinal)
    ) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("SELECT_CHRONO_HOURS"));
      return;
    }

    if (flagHours === -1) {
      setIsEditFaltaHoras(false);
      return;
    }

    if (
      isWeekend(formatDate(dateI)) &&
      !isSaturdayBeforeTime(formatDate(dateI), horaFinal)
    ) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("ERR_DATE_OR_HOUR"));
      return;
    }

    if (
      isWeekend(formatDate(dateI)) &&
      !isSaturdayBeforeTime(formatDate(dateI), horaInicial)
    ) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("SELECT_BEGIN_CORRECT_HOUR"));
      return;
    }

    if (
      feriados.includes(formatDate(dateI)) &&
      (horaInicial > "03:30" || horaFinal > "03:30")
    ) {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("HOLYDAY_ERR"));
      return;
    }

    formData.append("id_pedido", selectedRow);
    formData.append("dataInicio", formatDate(dateI));
    formData.append("horaInicio", horaInicial);
    formData.append("horaFim", horaFinal);
    formData.append("motivo", selectedMotivo.id);

    if (descricao == undefined || descricao.length == 0)
      formData.append("descricao", "");
    else if (descricao.length > 0) {
      formData.append("descricao", descricao.substring(0, 100));
    } else {
      setIsEditFaltaHoras(false);
      setErrorMessage(t("GENERAL_ERR"));
      return null;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/editFaltaHoras",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getFaltas();
        hideEditHoras();
        showNiceMessage(t("EDIT_ABSENSE_INFO"));
      } else {
        setErrorMessage(t("EDIT_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("EDIT_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsEditFaltaHoras(false);
      setSelectedFile(null);
    }
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const deleteRow = async (id_pedido) => {
    if (!csrfToken) return;
    if (!token) return;

    if (id_pedido == null || id_pedido == undefined) return;

    if (isDeleting) return;
    setIsDeleting(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/DeleteFalta",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({ id_pedido }),
        }
      );

      if (response.ok) {
        getFaltas();
        fetchUpcomingEventsData2();
        showNiceMessage2(t("DELETE_ABSENSE_INFO"));
      } else {
        setErrorMessage(t("DELETE_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("DELETE_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const [isDownloading, setIsDownloading] = useState(false);

  const downloaddFile = async (id_pedido) => {
    if (isDownloading) return;
    setIsDownloading(true);

    if (!csrfToken || !token) {
      setIsDownloading(false);
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/getFileUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken,
          },
          credentials: "include",
          body: JSON.stringify({ faltaId: id_pedido }),
        }
      );

      if (response.ok) {
        const contentDisposition = response.headers.get("content-disposition");
        let filename = "download";

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1].replace(/['"]/g, "");
          }
        }

        const blob = await response.blob();
        saveAs(blob, filename);
      } else {
        setErrorMessage(t("ERR_DOWNLOAD"));
      }
    } catch (error) {
      setErrorMessage(t("ERR_DOWNLOAD"));

      console.error("An error occurred:", error);
    } finally {
      setIsDownloading(false);
    }
  };
  const editDiasCompletosFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={editFaltaDiasCompletos}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const addDiasCompletosFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={addFaltaDiasCompletos}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const editHorasFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={editFaltaHoras}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const addHorasFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={addFaltaHoras}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const editFeriasFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={editFerias}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const addFeriasFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={addFerias}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );
  const currentPageTemplate = `{currentPage} ${t("OF2")} {totalPages}`;

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Dialog
            header="Calendário de Faltas & Férias"
            visible={showCalendar}
            style={{ width: "90vw", height: "90vh", borderRadius: "25px" }}
            onHide={closeCalendar}
          >
            <BigCalendar
              localizer={localizer}
              culture="pt"
              events={eventos}
              startAccessor="start"
              endAccessor="end"
              value={""}
              style={{ height: "70vh", borderRadius: "25px" }}
              views={["month", "week", "day"]} // Remover 'agenda' da lista de visualizações
              defaultView="month" // Definir a visualização padrão
              messages={{
                next: "Próximo",
                previous: "Anterior",
                today: "Hoje",
                month: "Mês",
                week: "Semana",
                day: "Dia",
                noEventsInRange: "Sem eventos neste intervalo.",
                showMore: function showMore(total) {
                  return "+ " + total;
                },
              }}
              eventPropGetter={(event) => {
                const backgroundColor =
                  event.id_motivo === null
                    ? "rgb(135, 45, 176)"
                    : event.id_motivo === "1"
                    ? "rgb(49, 163, 59)"
                    : "rgb(163, 49, 59)";

                return {
                  style: {
                    backgroundColor,
                    color: "white", // Cor do texto
                  },
                };
              }}
            />
          </Dialog>

          <Toolbar
            className="mb-3 datatable-transition"
            style={{ padding: "0.2rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate2} /* right={rightToolbarTemplate} */
          ></Toolbar>

          <Toolbar
            className="mb-3 botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={listaFaltas}
            dataKey="id_pedido"
            paginator
            rows={rows} // Número de linhas por página
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={currentPageTemplate}
            emptyMessage={t("NO_ABSENCE")}
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
            first={first} // Atributo para controlar a primeira linha visível
            onPage={onPageChange} // Atualiza o estado quando a página muda
          >
            <Column
              body={eliminarFalta}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
            <Column
              body={editarFalta}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
            <Column
              className="important-padding"
              body={estadoTemplate}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
            <Column
              field="motivo"
              header={t("MOTIVE")}
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>
            <Column
              field="Desp_Hierarquia"
              header={t("ORDER_HIERARCHY")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="Desp_SAFP"
              header={t("ORDER_SAFP")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="dataInicio"
              header={t("BEGIN_DATE")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="horaInicio"
              header={t("BEGIN_HOUR")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dataFim"
              header={t("END_DATE")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="horaFim"
              header={t("END_HOUR")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dias"
              header={t("TIME")}
              style={{ minWidth: "4rem" }}
            ></Column>

            <Column
              body={info}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
            <Column
              body={press}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
          </DataTable>

          <Dialog
            visible={infoDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<InfoHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={InfoFooter}
            onHide={hideInfo}
          >
            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                {t("DESCRIPTION")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.descricao}
              />
            </div>
            <div className="field">
              <label htmlFor="dataPedido" style={{ fontSize: "0.9rem" }}>
                {t("REQUEST_DATE")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.dataPedido}
                disabled
              />
            </div>
            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                {t("BOSS_OBSERVATIONS")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.Inf_Desp_Hierarquia}
              />
            </div>
            <div className="field">
              <label htmlFor="chefe" style={{ fontSize: "0.9rem" }}>
                {t("BOSS")}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.chefe}
                disabled
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("BOSS_INFO_DATE")}{" "}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.data_auto}
                disabled
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("SAFP_OBSERVATIONS")}{" "}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.safp_observacoes}
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>{t("SAFP")} </label>
              <InputText
                className="  procura-dialog"
                value={falta?.SAFP}
                disabled
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("SAFP_INFO_DATE")}{" "}
              </label>
              <InputText
                disabled
                className="  procura-dialog"
                value={falta?.data_safp}
              />
            </div>
            {showUpload2 && (
              <Button
                label={t("DOWNLOAD_FILE")}
                icon="pi  pi-download
            "
                className=" mr-2 procurasbutton"
                onClick={() => downloaddFile(falta?.id_pedido)}
                style={{ width: "65%", marginTop: "0.5rem " }}
                rounded
              />
            )}
          </Dialog>

          <Dialog
            visible={editDiasCompletosDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editDiasCompletosFooter}
            onHide={hideEditDiasCompletos}
          >
            <div className="field ">
              <label htmlFor="Motivo" style={{ fontSize: "0.9rem" }}>
                {t("MOTIVE")}
              </label>
              <Dropdown
                value={selectedMotivo}
                onChange={(e) => setSelectedMotivo(e.value)}
                options={motivos}
                optionLabel="motivo"
                className="w-full     md:w-19rem "
              />
            </div>
            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                {t("DESCRIPTION")}
              </label>
              <InputText
                id="Descricao"
                value={descricao}
                placeholder={
                  selectedMotivo?.id === 7 ? t("OBRIGATORIO") : t("OPTIONAL")
                }
                onChange={(e) => setDescricao(e.target.value)}
              />
            </div>

            {/*    <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dates}
                onChange={(e) => setDates(e.value)}
                selectionMode="range"
                dateFormat="dd/mm/yy"
                hideOnRangeSelection
              />
            </div> */}

            <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className="field">
              <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                {t("END_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateF}
                onChange={(e) => setDateF(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>

            {showUpload2 && (
              <Button
                label={t("DOWNLOAD_FILE")}
                icon="pi  pi-download
            "
                className=" mr-2  "
                onClick={() => downloaddFile(selectedRow)}
                style={{
                  width: "65%",
                  marginTop: "0.5rem ",
                  padding: "0.25rem 1rem",
                  borderRadius: "20px",
                }}
                rounded
              />
            )}
            {showUpload && (
              <div className="field">
                <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                  {t("ADD_FILE")}{" "}
                </label>
                <FileUpload
                  mode="basic"
                  name="demo[]"
                  url="/api/upload"
                  accept=".pdf, .png, .jpeg, .jpg, .heic"
                  maxFileSize={2000000}
                  chooseLabel={t("FILE")}
                  onSelect={handleFileSelect}
                  className="custom-file-upload"
                />
              </div>
            )}
            {showUpload && (
              <p id="erro2" style={{ color: import.meta.env.VITE_API_COLOR2 }}>
                {t("NOTE")}
              </p>
            )}

            <div style={{ color: "black", margin: "1rem 0" }}>
              {t("DAYS")}: <span style={{ fontWeight: 700 }}>{diasFalta}</span>
            </div>

            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessage}
            </div>

            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessageFile}
            </div>
          </Dialog>

          <Dialog
            visible={addDiasCompletosDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={addDiasCompletosFooter}
            onHide={hideAddDiasCompletos}
          >
            <div className="field ">
              <label htmlFor="Motivo" style={{ fontSize: "0.9rem" }}>
                {t("MOTIVE")}
              </label>
              <Dropdown
                value={selectedMotivo}
                onChange={(e) => setSelectedMotivo(e.value)}
                options={motivos}
                optionLabel="motivo"
                className="w-full     md:w-19rem "
              />
            </div>
            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                {t("DESCRIPTION")}
              </label>
              <InputText
                id="Descricao"
                value={descricao}
                placeholder={
                  selectedMotivo?.id === 7 ? t("OBRIGATORIO") : t("OPTIONAL")
                }
                onChange={(e) => setDescricao(e.target.value)}
              />
            </div>

            <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className="field">
              <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                {t("END_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateF}
                onChange={(e) => setDateF(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>

            {showUpload && (
              <div className="field">
                <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                  {t("ADD_FILE")}{" "}
                </label>
                <FileUpload
                  mode="basic"
                  name="demo[]"
                  url="/api/upload"
                  accept=".pdf, .png, .jpeg, .jpg, .heic"
                  maxFileSize={2000000}
                  chooseLabel={t("FILE")}
                  onSelect={handleFileSelect}
                  className="custom-file-upload"
                />
              </div>
            )}
            {showUpload && (
              <p id="erro2" style={{ color: import.meta.env.VITE_API_COLOR2 }}>
                {t("NOTE")}
              </p>
            )}

            <div style={{ color: "black", margin: "1rem 0" }}>
              {t("DAYS")}: <span style={{ fontWeight: 700 }}>{diasFalta}</span>
            </div>

            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessage}
            </div>

            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessageFile}
            </div>
          </Dialog>
          <Dialog
            visible={editHorasDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editHorasFooter}
            onHide={hideEditHoras}
          >
            <div className="field ">
              <label htmlFor="Motivo" style={{ fontSize: "0.9rem" }}>
                {t("MOTIVE")}
              </label>
              <Dropdown
                value={selectedMotivo}
                onChange={(e) => setSelectedMotivo(e.value)}
                options={motivos}
                optionLabel="motivo"
                className="w-full     md:w-19rem "
              />
            </div>
            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                {t("DESCRIPTION")}
              </label>
              <InputText
                id="Descricao"
                value={descricao}
                placeholder={
                  selectedMotivo?.id === 7 ? t("OBRIGATORIO") : t("OPTIONAL")
                }
                onChange={(e) => setDescricao(e.target.value)}
              />
            </div>

            <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_HOUR")}
              </label>
              <Calendar
                value={horaI}
                onChange={(e) => setHoraI(e.value)}
                timeOnly
              />
            </div>
            <div className="field">
              <label htmlFor="horaFim" style={{ fontSize: "0.9rem" }}>
                {t("END_HOUR")}
              </label>
              <Calendar
                value={horaF}
                onChange={(e) => setHoraF(e.value)}
                timeOnly
              />
            </div>

            {showUpload2 && (
              <Button
                label={t("DOWNLOAD_FILE")}
                icon="pi  pi-download
            "
                className=" mr-2  "
                onClick={() => downloaddFile(selectedRow)}
                style={{
                  width: "65%",
                  marginTop: "0.5rem ",
                  padding: "0.25rem 1rem",
                  borderRadius: "20px",
                }}
                rounded
              />
            )}

            {showUpload && (
              <div className="field">
                <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                  {t("ADD_FILE")}{" "}
                </label>
                <FileUpload
                  mode="basic"
                  name="demo[]"
                  url="/api/upload"
                  accept=".pdf, .png, .jpeg, .jpg, .heic"
                  maxFileSize={2000000}
                  chooseLabel={t("FILE")}
                  onSelect={handleFileSelect}
                  className="custom-file-upload"
                />
              </div>
            )}
            {showUpload && (
              <p id="erro2" style={{ color: import.meta.env.VITE_API_COLOR2 }}>
                {t("NOTE")}
              </p>
            )}

            <div style={{ color: "black", margin: "1rem 0" }}>
              {t("TIME")}: <span style={{ fontWeight: 700 }}>{horasFalta}</span>
            </div>

            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessage}
            </div>
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessageFile}
            </div>
          </Dialog>

          <Dialog
            visible={addHorasDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={addHorasFooter}
            onHide={hideAddHoras}
          >
            <div className="field ">
              <label htmlFor="Motivo" style={{ fontSize: "0.9rem" }}>
                {t("MOTIVE")}
              </label>
              <Dropdown
                value={selectedMotivo}
                onChange={(e) => setSelectedMotivo(e.value)}
                options={motivos}
                optionLabel="motivo"
                className="w-full     md:w-19rem "
              />
            </div>
            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                {t("DESCRIPTION")}
              </label>
              <InputText
                id="Descricao"
                value={descricao}
                placeholder={
                  selectedMotivo?.id === 7 ? t("OBRIGATORIO") : t("OPTIONAL")
                }
                onChange={(e) => setDescricao(e.target.value)}
              />
            </div>

            <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_HOUR")}
              </label>
              <Calendar
                value={horaI}
                onChange={(e) => setHoraI(e.value)}
                timeOnly
              />
            </div>
            <div className="field">
              <label htmlFor="horaFim" style={{ fontSize: "0.9rem" }}>
                {t("END_HOUR")}
              </label>
              <Calendar
                value={horaF}
                onChange={(e) => setHoraF(e.value)}
                timeOnly
              />
            </div>

            {showUpload && (
              <div className="field">
                <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                  {t("ADD_FILE")}{" "}
                </label>
                <FileUpload
                  mode="basic"
                  name="demo[]"
                  url="/api/upload"
                  accept=".pdf, .png, .jpeg, .jpg, .heic"
                  maxFileSize={2000000}
                  chooseLabel={t("FILE")}
                  onSelect={handleFileSelect}
                  className="custom-file-upload"
                />
              </div>
            )}
            {showUpload && (
              <p id="erro2" style={{ color: import.meta.env.VITE_API_COLOR2 }}>
                {t("NOTE")}
              </p>
            )}

            <div style={{ color: "black", margin: "1rem 0" }}>
              {t("TIME")}: <span style={{ fontWeight: 700 }}>{horasFalta}</span>
            </div>

            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessage}
            </div>
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessageFile}
            </div>
          </Dialog>

          <Dialog
            visible={editFeriasDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editFeriasFooter}
            onHide={hideEditFerias}
          >
            <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateII}
                onChange={(e) => setDateII(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_HOUR")}
              </label>
              <Dropdown
                value={selectedTurno}
                onChange={(e) => setSelectedTurno(e.value)}
                options={SELECT_HOURS}
                optionLabel="text"
                className="w-full     md:w-19rem "
              />
            </div>
            <div className="field">
              <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                {t("END_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateFF}
                onChange={(e) => setDateFF(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaFim" style={{ fontSize: "0.9rem" }}>
                {t("END_HOUR")}
              </label>
              <Dropdown
                value={selectedTurno2}
                onChange={(e) => setSelectedTurno2(e.value)}
                options={SELECT_HOURS2}
                optionLabel="text"
                className="w-full     md:w-19rem "
              />
            </div>

            <div style={{ color: "black", margin: "1rem 0" }}>
              {t("DAYS")}: <span style={{ fontWeight: 700 }}>{diasFerias}</span>
            </div>

            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessageFile}
            </div>
          </Dialog>

          <Dialog
            visible={addFeriasDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={addFeriasFooter}
            onHide={hideAddFerias}
          >
            <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateII}
                onChange={(e) => setDateII(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_HOUR")}
              </label>
              <Dropdown
                value={selectedTurno}
                onChange={(e) => setSelectedTurno(e.value)}
                options={SELECT_HOURS}
                optionLabel="text"
                className="w-full     md:w-19rem "
              />
            </div>
            <div className="field">
              <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                {t("END_DATE")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateFF}
                onChange={(e) => setDateFF(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field">
              <label htmlFor="horaFim" style={{ fontSize: "0.9rem" }}>
                {t("END_HOUR")}
              </label>
              <Dropdown
                value={selectedTurno2}
                onChange={(e) => setSelectedTurno2(e.value)}
                options={SELECT_HOURS2}
                optionLabel="text"
                className="w-full     md:w-19rem "
              />
            </div>

            <div style={{ color: "black", margin: "1rem 0" }}>
              {t("DAYS")}: <span style={{ fontWeight: 700 }}>{diasFerias}</span>
            </div>

            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {errorMessageFile}
            </div>
          </Dialog>

          <Dialog
            visible={procuraDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<ProcurasHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={procuraDialogFooter}
            onHide={hideProcuras}
          >
            <div className="field ">
              <label htmlFor="Motivo" style={{ fontSize: "0.9rem" }}>
                {t("MOTIVE")}
              </label>
              <Dropdown
                value={selectedMotivo}
                onChange={(e) => setSelectedMotivo(e.value)}
                options={motivos2}
                optionLabel="motivo"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field">
              <label htmlFor="dataInicio" style={{ fontSize: "0.9rem" }}>
                {t("BEGIN_DATE2")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                className="   procura-dialog"
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
                placeholder={t("OPTIONAL")}
              />
            </div>

            <div className="field">
              <label htmlFor="dataFim" style={{ fontSize: "0.9rem" }}>
                {t("END_DATE2")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateF}
                className="   procura-dialog"
                onChange={(e) => setDateF(e.value)}
                dateFormat="dd/mm/yy"
                placeholder={t("OPTIONAL")}
              />
            </div>

            <div className="field ">
              <label htmlFor="Despacho" style={{ fontSize: "0.9rem" }}>
                {t("ORDER_HIERARCHY3")}
              </label>
              <Dropdown
                value={selectedDespachoHierarquia}
                onChange={(e) => setSelectedDespachoHierarquia(e.value)}
                options={despachoHierarquia}
                optionLabel="despacho"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field ">
              <label htmlFor="Despacho" style={{ fontSize: "0.9rem" }}>
                {t("ORDER_SAFP")}
              </label>
              <Dropdown
                value={selectedDespacho}
                onChange={(e) => setSelectedDespacho(e.value)}
                options={despacho}
                optionLabel="despacho"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil pencil2"
                    label={t("CANCEL")}
                    text
                    onClick={(event) => {
                      hide(event);
                      // reject();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  ></Button>
                  <Button
                    label={t("YES")}
                    text
                    onClick={(event) => {
                      hide(event);
                      deleteRow(selectedRow);
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default User;
