/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { locale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import saveAs from "file-saver";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import pt from "date-fns/locale/pt";
const locales = {
  pt: pt,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
import { InputText } from "primereact/inputtext";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import React, { useEffect, useRef, useState } from "react";

interface Motivo {
  id: number;
  motivo: string;
}

interface Trabalhador {
  id: number;
  trabalhador: string;
}

interface Despacho {
  id: number;
  despacho: string;
}
/* @todo Used 'as any' for types here. Will fix in next version due to onSelectionChange event type issue. */
const SAFPhistorico = () => {
  // Aplicando a localidade globalmente
  locale(i18n.language);
  const { t } = useTranslation();
  const [descricao, setDescricao] = useState<string | undefined>(undefined); // Estado para armazenar a descrição
  const [infoHierarquia, setInfoHierarquia] = useState<string | undefined>(
    undefined
  ); // Estado para armazenar a descrição
  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage
  const [errorMessage3, setErrorMessage3] = useState("");

  const [falta, setFalta] = useState<Falta | null>(null); // Exemplo de inicialização com null
  const [listaFaltas, setFaltas] = useState<Falta[]>([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [eventos, setEventos] = useState([]);

  const options = [{ label: t("YES"), value: "1" }];

  const jwtToken = localStorage.getItem("jwtToken");
  const xsrfToken = localStorage.getItem("XSRF-TOKEN");
  useEffect(() => {
    getPedidosSAFPhistorico();

    fetchUpcomingEventsData4();
    fetchUpcomingEventsData5();
    fetchUpcomingEventsData6();
    fetchUpcomingEventsData7();
    fetchUpcomingEventsData8();
    fetchUpcomingEventsData9();
    fetchUpcomingEventsData10();
  }, []);

  interface Feriado {
    dataFeriado: string;
    descricao: string;
  }
  interface Feriado2 {
    dataFeriado2: string;
  }
  const [feriados, setFeriados] = useState<Feriado[]>([]);

  const [feriados2, setFeriados2] = useState<Feriado2[]>([]);

  useEffect(() => {
    if (feriados.length > 0 && listaFaltas.length >= 0) {
      // Aqui você pode formatar as faltas para eventos com os feriados já carregados
      const eventosFormatados = formatarFaltasParaEventos(listaFaltas);
      setEventos(eventosFormatados);
    }
  }, [feriados, listaFaltas]); // Reage às mudanças de 'feriados' e 'listaFaltas'

  const formatarFaltasParaEventos = (faltas) => {
    const eventos = [];
    faltas.forEach((falta) => {
      const {
        dataInicio,
        dataFim,
        trabalhador,
        horaFim,
        id_motivo,
        id_despacho_SAFP,
        id_desp_Chefia,
      } = falta;

      if (id_despacho_SAFP == 2 || id_desp_Chefia == 1) return;
      // Converter as datas de "dd/mm/aaaa" para "aaaa-mm-dd"
      const [diaInicio, mesInicio, anoInicio] = dataInicio.split("/");
      const [diaFim, mesFim, anoFim] = dataFim.split("/");

      // Criar objetos Date no formato ISO
      let inicio = new Date(`${anoInicio}-${mesInicio}-${diaInicio}`);
      inicio.setHours(0, 0, 0, 0); // Define a hora como 00:00:00.000
      const fim = new Date(`${anoFim}-${mesFim}-${diaFim}`);

      // Gerar um evento para cada dia no intervalo
      while (inicio <= fim) {
        if (
          inicio.getTime() === fim.getTime() &&
          horaFim &&
          horaFim < "03:31"
        ) {
          break;
        }

        if (
          inicio.getDay() !== 0 &&
          inicio.getDay() !== 6 &&
          !feriados2.includes(formatDate(inicio))
        ) {
          eventos.push({
            title: `${trabalhador}`,
            start: new Date(inicio), // Data de início do evento
            end: new Date(inicio), // Data de fim do evento (mesmo dia)
            id_motivo: id_motivo,
          });
        }
        // Avançar para o próximo dia
        inicio.setDate(inicio.getDate() + 1);
      }
    });

    feriados.forEach((feriado) => {
      const dataFeriado = new Date(feriado.dataFeriado);

      // Adicionar feriado ao array de eventos
      eventos.push({
        title: feriado.descricao,
        start: dataFeriado,
        end: dataFeriado,
        id_motivo: null, // Sem motivo associado, mas pode ser adaptado
      });
    });

    return eventos;
  };

  const getPedidosSAFPhistorico = async () => {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getPedidosSAFPHistorico",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );
        const data = await response.json();
        if (data[0].error === "1") {
          setFaltas([]);
          setErrorMessage3("0 " + t("SAFP_HISTORIC_INFO2"));
        } else {
          setFaltas(data.reverse());
          setErrorMessage3(data.length + " " + t("SAFP_HISTORIC_INFO2"));
        }
      }
    } catch (error) {
      console.error("Could not getPedidosSafp", error);
    }
  };

  const [trabalhadores, setTrabalhadores] = useState<Trabalhador[]>([]);
  const [selectedTrabalhador, setSelectedTrabalhador] =
    useState<Trabalhador | null>(null);

  async function fetchUpcomingEventsData8() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTrabalhadoresFerias",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setTrabalhadores(
          data.map((dado: { ID: number; nome: string }) => ({
            id: dado.ID,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [chefes, setChefes] = useState<Trabalhador[]>([]);
  const [selectedChefe, setSelectedChefe] = useState<Trabalhador | null>(null);

  async function fetchUpcomingEventsData9() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getChefes",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setChefes(
          data.map((dado: { id: number; nome: string }) => ({
            id: dado.id,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [safp, setSAFP] = useState<Trabalhador[]>([]);
  const [selectedSAFP, setSelectedSAFP] = useState<Trabalhador | null>(null);

  async function fetchUpcomingEventsData10() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getSAFP",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setSAFP(
          data.map((dado: { id: number; nome: string }) => ({
            id: dado.id,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [motivos, setMotivos] = useState<Motivo[]>([]);
  const [selectedMotivo, setSelectedMotivo] = useState<Motivo | null>(null);

  async function fetchUpcomingEventsData4() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getMotivos",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setMotivos(
          data.map((dado: { id: number; motivo: string }) => ({
            id: dado.id,
            motivo: dado.motivo,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  const [despacho, setDespacho] = useState<Despacho[]>([]);
  const [selectedDespacho, setSelectedDespacho] = useState<Despacho | null>(
    null
  );

  const [despacho2, setDespacho2] = useState<Despacho[]>([]);

  const [selectedDespacho2, setSelectedDespacho2] = useState<Despacho | null>(
    null
  );

  async function fetchUpcomingEventsData5() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getDespachosSAFP",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setDespacho(
          data
            .slice(2, -1) // Remove os primeiros dois itens e o último item
            .map((dado: { id: any; despacho: any }) => ({
              id: dado.id,
              despacho: dado.despacho,
            }))
        );

        setDespacho2(
          data
            .slice(1, -1) // Remove o primeiro e o último item
            .map((dado: { id: any; despacho: any }) => ({
              id: dado.id,
              despacho: dado.despacho,
            }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  const [despachoHierarquia, setDespachoHierarquia] = useState<Despacho[]>([]);
  const [selectedDespachoHierarquia, setSelectedDespachoHierarquia] =
    useState<Despacho | null>(null);

  async function fetchUpcomingEventsData6() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getDespachosHierarquia ",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setDespachoHierarquia(
          data.slice(1).map((dado: { id: any; despacho: any }) => ({
            id: dado.id,
            despacho: dado.despacho,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  async function fetchUpcomingEventsData7() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getFeriados ",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setFeriados2(
          data.map((item: { dataFeriado: string }) => {
            // Dividir a string da data pelo caractere '/'
            const partes = item.dataFeriado.split("/");
            // Criar a data no formato desejado ('YYYY-MM-DD')
            return `${partes[2]}-${partes[1]}-${partes[0]}`;
          })
        );

        setFeriados(
          data.map((item: { dataFeriado: string; descricao: string }) => {
            // Dividir a string da data pelo caractere '/'
            const partes = item.dataFeriado.split("/");
            // Criar a data no formato desejado ('YYYY-MM-DD') e incluir a descrição
            return {
              dataFeriado: `${partes[2]}-${partes[1]}-${partes[0]}`,
              descricao: item.descricao,
            };
          })
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setFeriados([]); // Opcional: definir um estado vazio caso ocorra um erro
    }
  }

  type Ferias = {
    id: string;
    dias: number;
    [key: string]: string | number;
  };

  type Falta = {
    id_pedido: number;
    id_motivo: number;
    motivo: string;
    estado: string;
    descricao: string;
    chefia: string;
    Inf_Desp_Hierarquia: string;
    Desp_SAFP: string;
    dataInicio: string;
    horaInicio: string;
    dataFim: string;
    horaFim: string;
    dias: string;
    dataPedido: string;
    ficheiro: number;
    id_despacho_SAFP: number;
    id_desp_Chefia: string;
    dataAutorizacaoSAFP: string;
    SAFPAutorizacao: string;
    chefeAutorizacao: string;
    dataAutorizacao: string;
    safp_observacoes: string;
    [key: string]: string | number;
  };

  type Ficheiro = {
    id: number;
    nome: string;
    [key: string]: string | number;
  };

  const [procuraDialog, setProcuraDialog] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const [editDecisaoDialog, setEditDecisaoDialog] = useState(false);
  const [anularFeriasDialog, setAnularFeriasDialog] = useState(false);
  const [anularFaltaDialog, setAnularFaltaDialog] = useState(false);

  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    document.body.classList.add("no-scroll");
    setSelectedMotivo(null);
    setSelectedSAFP(null);
    setSelectedChefe(null);
    setSelectedTrabalhador(null);
    setSelectedDespacho(null);
    setSelectedDespacho2(null);
    setSelectedDespachoHierarquia(null);
    setDateI(undefined);
    setDateF(undefined);
    setDescricao("");
    setObservacao("");
  };

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "error",
      icon: "pi pi-check",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const showNiceMessage2 = (message) => {
    toast.current?.show({
      severity: "success",
      icon: "pi pi-trash",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const showInfoMessage = (message) => {
    toast.current?.show({
      severity: import.meta.env.VITE_API_SEVERITY,
      icon: "pi pi-search",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 1500,
    });
  };

  const openProcuras = () => {
    handleVacationButtonClick();
    clean();

    setProcuraDialog(true);
  };
  const cancelFalta = (rowData) => {
    document.body.classList.add("no-scroll");
    handleVacationButtonClick();
    setIcon("pi-exclamation-triangle");
    setSelectedRow(rowData.id_pedido);
    setSelectedOption(null);
    setErrorMessage("");
    setObservacao("");
    if (rowData.id_motivo == "1") {
      setHeader(t("CANCEL_VACATION"));
      setAnularFeriasDialog(true);
    } else {
      setHeader(t("CANCEL_ABSENCE"));
      setAnularFaltaDialog(true);
    }
  };

  const editFalta = (rowData) => {
    document.body.classList.add("no-scroll");
    handleVacationButtonClick();
    setObservacao("");

    setSelectedDespacho(null);

    setSelectedRow(rowData.id_pedido);
    setIcon("pi-pencil");

    setHeader(t("EDIT_DESPACHO"));
    setErrorMessage("");

    setEditDecisaoDialog(true);
  };

  const hideProcuras = () => {
    setProcuraDialog(false);
    close();
  };

  const hideEditDecisao = () => {
    setEditDecisaoDialog(false);
    close();
  };

  const hideAnularFerias = () => {
    setAnularFeriasDialog(false);
    close();
  };

  const hideAnularFalta = () => {
    setAnularFaltaDialog(false);
    close();
  };

  const hideInfo = () => {
    setInfoDialog(false);
    close();
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
    setSelectedRow2(null); // Limpa a seleção de todas as linhas
  };

  const openInfo = (rowData) => {
    setFalta(rowData);
    document.body.classList.add("no-scroll");
    handleVacationButtonClick();
    setSelectedRow2(rowData.id_pedido);
    setInfoDialog(true);
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if isoString is undefined or null
    const date = new Date(dateString);

    // Get the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0"); // Pad single-digit days with a leading zero

    // Return formatted date
    return `${year}-${month}-${day}`;
  };

  const [IsProcura, setIsProcuras] = useState(false);

  const procura = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsProcura) return;
    setIsProcuras(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/getPedidosSAFPHistorico2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: JSON.stringify({
            motivo: selectedMotivo?.id || "",
            trabalhador: selectedTrabalhador?.id || "",
            chefe: selectedChefe?.id || "",
            descricao: descricao?.substring(0, 100) || "",
            dataInicio: dateI ? formatDate(dateI) : "", // Check if dateI is defined before calling formatDate
            dataFim: dateF ? formatDate(dateF) : "", // Check if dateF is defined before calling formatDate
            infoHierarquia: infoHierarquia || "",
            autoChefia: selectedDespachoHierarquia?.id || "",
            SAFP: selectedSAFP?.id || "",
            observacao: observacao?.substring(0, 100) || "",
            decisao: selectedDespacho2?.id || "",
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data);
        setFirst(0);
        hideProcuras();
        showInfoMessage(t("SEARCH_INFO"));
      } else {
        setErrorMessage(t("SEARCH_INFO_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("SEARCH_INFO_ERR"));

      console.error("An error occurred:", error);
    } finally {
      setIsProcuras(false);
    }
  };

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = async (id_pedido) => {
    if (isDownloading) return;
    setIsDownloading(true);

    if (!csrfToken || !token) {
      setIsDownloading(false);
      return;
    }

    try {
      const response = await fetch(import.meta.env.VITE_API_URL + "/getFile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
          "x-xsrf-token": csrfToken,
          "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
        },
        credentials: "include",
        body: JSON.stringify({ faltaId: id_pedido }),
      });

      if (response.ok) {
        const contentDisposition = response.headers.get("content-disposition");
        let filename = "download";

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1].replace(/['"]/g, "");
          }
        }

        const blob = await response.blob();
        saveAs(blob, filename);
      } else {
        setErrorMessage(t("ERR_DOWNLOAD"));
      }
    } catch (error) {
      setErrorMessage(t("ERR_DOWNLOAD"));

      console.error("An error occurred:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const reset = () => {
    setFirst(0);
    getPedidosSAFPhistorico();
  };

  const [first, setFirst] = useState(0); // Estado para controlar a primeira linha visível
  const [rows, setRows] = useState(10); // Estado para controlar o número de linhas por página

  const onPageChange = (e) => {
    setFirst(e.first); // Atualiza o estado da primeira linha visível
    setRows(e.rows); // Atualiza o estado do número de linhas por página
  };

  const [showCalendar, setShowCalendar] = useState(false);

  const openCalendar = () => {
    setShowCalendar(true);
  };

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2 buttons-container ">
          <Button
            label={t("SEARCH")}
            icon="pi pi-search"
            className=" mr-2 procurasbutton"
            onClick={openProcuras}
            rounded
          />

          <Button
            label={t("CLEAN")}
            icon="pi  pi-refresh
            "
            className=" mr-2 procurasbutton"
            onClick={reset}
            rounded
          />
          <Button
            label={t("SHOW_CALENDAR")}
            icon="pi pi-calendar"
            className="mr-2 procurasbutton"
            onClick={openCalendar}
            rounded
          />
        </div>
      </React.Fragment>
    );
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const downloadFileButton = (rowData) => {
    if (rowData.ficheiro === "1") {
      return (
        <>
          <Button
            icon="pi pi-download"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "#323232",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => downloadFile(rowData.id_pedido)}
          />
        </>
      );
    } else {
      return null;
    }
  };
  const converterData = (dataString: string) => {
    const partes = dataString.split("/");
    // Convertendo as partes da data para números com parseInt
    const ano = parseInt(partes[2], 10);
    const mes = parseInt(partes[1], 10) - 1; // Meses começam do 0 em JavaScript
    const dia = parseInt(partes[0], 10);

    return new Date(ano, mes, dia);
  };

  const editarFalta = (rowData) => {
    const dataAtual = new Date();
    const dataAutorizacaoSAFP = converterData(rowData.dataAutorizacaoSAFP);
    const diferencaEmTempo =
      dataAtual.getTime() - dataAutorizacaoSAFP.getTime();
    const diferencaEmDias = diferencaEmTempo / (1000 * 3600 * 24);
    if (rowData.id_despacho_SAFP != 2 && diferencaEmDias <= 60) {
      return (
        <>
          <Button
            icon="pi pi-pencil"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "#323232",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => editFalta(rowData)}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const anularFalta = (rowData) => {
    const dataAtual = new Date();
    const dataAutorizacaoSAFP = converterData(rowData.dataAutorizacaoSAFP);
    const diferencaEmTempo =
      dataAtual.getTime() - dataAutorizacaoSAFP.getTime();
    const diferencaEmDias = diferencaEmTempo / (1000 * 3600 * 24);
    if (
      rowData.id_despacho_SAFP != 2 &&
      diferencaEmDias <= 60 &&
      (rowData.id_motivo != 1 ||
        (rowData.id_motivo == 1 && rowData.id_desp_Chefia === "2"))
    ) {
      return (
        <>
          <Button
            icon="pi pi-exclamation-triangle"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "#323232",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => cancelFalta(rowData)}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const info = (rowData: Falta) => {
    return (
      <>
        <Button
          icon="pi pi-info-circle"
          size="small"
          rounded
          className="  pencil"
          style={{
            background: "none",
            color: "#323232",
            border: "1px solid rgb(231, 238, 248)",
          }}
          onClick={() => openInfo(rowData)}
        />
      </>
    );
  };

  const [IsAuth, setIsAuth] = useState(false);

  const alterarDecisao = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAuth) return;
    setIsAuth(true);

    let id_pedido = "";
    let informacao = "";
    if (selectedRow !== null && selectedRow !== undefined) {
      id_pedido = selectedRow;
    } else {
      setIsAuth(false);
      return null;
    }
    if (
      selectedDespacho !== null &&
      selectedDespacho !== undefined &&
      selectedDespacho.id > 1
    ) {
    } else {
      setIsAuth(false);
      setErrorMessage(t("SELECT_DECISION"));
      return null;
    }

    if (observacao == undefined || observacao.length == 0) {
    } else if (observacao.length > 0) {
      informacao = observacao.substring(0, 100);
    } else {
      setIsAuth(false);
      return null;
    }
    const formData = new FormData();

    formData.append("id_pedido", id_pedido);
    formData.append("observacao", informacao);
    formData.append("justificacao", selectedDespacho.id);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/editAuthSAFP",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getPedidosSAFPhistorico();
        hideEditDecisao();
        showNiceMessage(t("DECISION_CHANGE_SUCCESS"));
      } else {
        setErrorMessage(t("ERR_DECISION"));
      }
    } catch (error) {
      setErrorMessage(t("ERR_DECISION"));
      console.error("An error occurred:", error);
    } finally {
      setIsAuth(false);
    }
  };

  const [IsCancelVacation, setIsCancelVacation] = useState(false);

  const feriasCancel = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsCancelVacation) return;
    setIsCancelVacation(true);

    let id_pedido = "";
    let informacao = "";
    if (selectedRow !== null && selectedRow !== undefined) {
      id_pedido = selectedRow;
    } else {
      setIsCancelVacation(false);
      return null;
    }

    if (
      selectedOption !== null &&
      selectedOption !== undefined &&
      selectedOption == "1"
    ) {
    } else {
      setIsCancelVacation(false);
      setErrorMessage(t("SELECT_DECISION"));
      return null;
    }

    if (observacao == undefined || observacao.length == 0) {
    } else if (observacao.length > 0) {
      informacao = observacao.substring(0, 100);
    } else {
      setIsCancelVacation(false);
      return null;
    }
    const formData = new FormData();

    formData.append("id_pedido", id_pedido);
    formData.append("observacao", informacao);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/cancelVacation",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getPedidosSAFPhistorico();
        hideAnularFerias();
        showNiceMessage(t("ANULATED_VACATIONS"));
      } else {
        setErrorMessage(t("ERR_VACATION"));
      }
    } catch (error) {
      setErrorMessage(t("ERR_VACATION"));
      console.error("An error occurred:", error);
    } finally {
      setIsCancelVacation(false);
    }
  };

  const [IsCancelFalta, setIsCancelFalta] = useState(false);

  const faltaCancel = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsCancelFalta) return;
    setIsCancelFalta(true);

    let id_pedido = "";
    let informacao = "";
    if (selectedRow !== null && selectedRow !== undefined) {
      id_pedido = selectedRow;
    } else {
      setIsCancelFalta(false);
      return null;
    }

    if (
      selectedOption !== null &&
      selectedOption !== undefined &&
      selectedOption == "1"
    ) {
    } else {
      setIsCancelFalta(false);
      setErrorMessage(t("SELECT_DECISION"));
      return null;
    }

    if (observacao == undefined || observacao.length == 0) {
    } else if (observacao.length > 0) {
      informacao = observacao.substring(0, 100);
    } else {
      setIsCancelFalta(false);
      return null;
    }
    const formData = new FormData();

    formData.append("id_pedido", id_pedido);
    formData.append("observacao", informacao);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/cancelFalta",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getPedidosSAFPhistorico();
        hideAnularFalta();
        showNiceMessage(t("VACATION_DEL_SUCCESS"));
      } else {
        setErrorMessage(t("ERR_VACATION_DEL"));
      }
    } catch (error) {
      setErrorMessage(t("ERR_VACATION_DEL"));
      console.error("An error occurred:", error);
    } finally {
      setIsCancelFalta(false);
    }
  };
  const anularFaltaFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        className="  button2"
        text
        onClick={faltaCancel}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const editDiasCompletosFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={alterarDecisao}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const editDecisaoFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        className="  button2"
        onClick={alterarDecisao}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const anularFeriasFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        className="  button2"
        icon="pi pi-check"
        text
        onClick={feriasCancel}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );
  const procuraDialogFooter = (
    <>
      <Button
        label={t("SEARCH")}
        icon="pi pi-search"
        text
        className="   procurasbutton"
        onClick={procura}
      />
    </>
  );

  const InfoFooter = <></>;

  const ProcurasHeader = () => (
    <div>
      <i
        className="pi pi-search"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.3rem", color: "#323232  " }}>
        {t("SEARCH")}
      </label>
    </div>
  );

  const InfoHeader = () => (
    <div>
      <i
        className="pi pi-info-circle"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem", color: "#323232  " }}>
        {t("ADDITIONAL_INFO")}
      </label>
    </div>
  );

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const AnularFaltaHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const AnularFeriasHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const [dateI, setDateI] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [dateF, setDateF] = useState<Date | undefined>(undefined); // Inicializado como undefined

  const [observacao, setObservacao] = useState<string | undefined>(undefined); // Estado para armazenar a descrição
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRow2, setSelectedRow2] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    if (rowData.id_pedido === selectedRow) {
      return "highlight2";
    } else if (rowData.id_pedido === selectedRow2) {
      return "highlight3";
    } else {
      return "";
    }
  };

  const currentPageTemplate = `{currentPage} ${t("OF2")} {totalPages}`;

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Dialog
            header="Calendário de Faltas & Férias"
            visible={showCalendar}
            style={{ width: "90vw", height: "90vh", borderRadius: "25px" }}
            onHide={closeCalendar}
          >
            <BigCalendar
              localizer={localizer}
              culture="pt"
              events={eventos}
              startAccessor="start"
              endAccessor="end"
              value={""}
              style={{ height: "70vh", borderRadius: "25px" }}
              views={["month", "week", "day"]} // Remover 'agenda' da lista de visualizações
              defaultView="month" // Definir a visualização padrão
              messages={{
                next: "Próximo",
                previous: "Anterior",
                today: "Hoje",
                month: "Mês",
                week: "Semana",
                day: "Dia",
                noEventsInRange: "Sem eventos neste intervalo.",
                showMore: function showMore(total) {
                  return "+ " + total;
                },
              }}
              eventPropGetter={(event) => {
                const backgroundColor =
                  event.id_motivo === null
                    ? "rgb(135, 45, 176)"
                    : event.id_motivo === "1"
                    ? "rgb(49, 163, 59)"
                    : "rgb(163, 49, 59)";

                return {
                  style: {
                    backgroundColor,
                    color: "white", // Cor do texto
                  },
                };
              }}
            />
          </Dialog>

          <Toolbar
            className="  botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>
          {errorMessage3 && (
            <p
              id="erro3"
              style={{
                paddingLeft: "0.8rem",
                color: import.meta.env.VITE_API_COLOR,
              }}
            >
              {errorMessage3}
            </p>
          )}
          <DataTable
            ref={dt}
            value={listaFaltas}
            dataKey="id_pedido"
            paginator
            rows={rows}
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={currentPageTemplate}
            emptyMessage={t("NO_ABSENCE")}
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
            first={first} // Atributo para controlar a primeira linha visível
            onPage={onPageChange} // Atualiza o estado quando a página muda
          >
            <Column
              body={downloadFileButton}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>

            <Column
              body={editarFalta}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>

            <Column
              body={anularFalta}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>

            <Column
              field="trabalhador"
              header={t("WORKER")}
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>

            <Column
              field="motivo"
              header={t("MOTIVE")}
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>

            <Column
              field="dataInicio"
              header={t("BEGIN_DATE")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="horaInicio"
              header={t("BEGIN_HOUR")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dataFim"
              header={t("END_DATE")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="horaFim"
              header={t("END_HOUR")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dias"
              header={t("TIME")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="chefia"
              header={t("ORDER_HIERARCHY2")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="Desp_SAFP"
              header={t("ORDER_SAFP2")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              body={info}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
          </DataTable>

          <Dialog
            visible={infoDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<InfoHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={InfoFooter}
            onHide={hideInfo}
          >
            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                {t("DESCRIPTION")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.descricao}
              />
            </div>
            <div className="field">
              <label htmlFor="dataPedido" style={{ fontSize: "0.9rem" }}>
                {t("REQUEST_DATE")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.dataPedido}
                disabled
              />
            </div>
            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                {t("BOSS_OBSERVATIONS")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.Inf_Desp_Hierarquia}
              />
            </div>
            <div className="field">
              <label htmlFor="chefe" style={{ fontSize: "0.9rem" }}>
                {t("BOSS")}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.chefeAutorizacao}
                disabled
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("BOSS_INFO_DATE")}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.dataAutorizacao}
                disabled
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("SAFP_OBSERVATIONS")}{" "}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.safp_observacoes}
              />
            </div>

            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>{t("SAFP")} </label>
              <InputText
                className="  procura-dialog"
                value={falta?.SAFPAutorizacao}
                disabled
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("SAFP_INFO_DATE")}{" "}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.dataAutorizacaoSAFP}
                disabled
              />
            </div>
          </Dialog>

          <Dialog
            visible={editDecisaoDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog2"
            footer={editDecisaoFooter}
            onHide={hideEditDecisao}
          >
            <div className="field ">
              <label htmlFor="Decisao" style={{ fontSize: "0.9rem" }}>
                {t("DECISION")}
              </label>
              <Dropdown
                value={selectedDespacho}
                onChange={(e) => setSelectedDespacho(e.value)}
                options={despacho}
                optionLabel="despacho"
                placeholder={t("CHANGE_DECISION")}
                className="w-full     md:w-19rem anular"
              />
            </div>
            {errorMessage && (
              <div
                style={{
                  color: "red",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {errorMessage}
              </div>
            )}
            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                {t("OBSERVAIONS")}
              </label>
              <InputText
                id="observacoes"
                placeholder={t("OPTIONAL")}
                value={observacao}
                className="anular2"
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
          </Dialog>

          <Dialog
            visible={anularFeriasDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<AnularFeriasHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog2"
            footer={anularFeriasFooter}
            onHide={hideAnularFerias}
          >
            <div className="field ">
              <label htmlFor="Decisao" style={{ fontSize: "0.9rem" }}>
                {t("DECISION")}
              </label>
              <Dropdown
                id="Decisao"
                value={selectedOption}
                options={options}
                onChange={(e) => setSelectedOption(e.value)}
                optionLabel="label"
                placeholder={t("CANCEL_VACATION_TEXT")}
                className="w-full md:w-19rem anular"
              />
            </div>
            {errorMessage && (
              <div
                style={{
                  color: "red",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {errorMessage}
              </div>
            )}
            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                {t("OBSERVAIONS")}
              </label>
              <InputText
                id="observacoes"
                placeholder={t("OPTIONAL")}
                value={observacao}
                className="anular2"
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
          </Dialog>

          <Dialog
            visible={anularFaltaDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<AnularFaltaHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog2"
            footer={anularFaltaFooter}
            onHide={hideAnularFalta}
          >
            <div className="field ">
              <label htmlFor="Decisao" style={{ fontSize: "0.9rem" }}>
                {t("DECISION")}
              </label>
              <Dropdown
                id="Decisao"
                value={selectedOption}
                options={options}
                onChange={(e) => setSelectedOption(e.value)}
                optionLabel="label"
                placeholder={t("CANCEL_ABSENCE_TEXT")}
                className="w-full md:w-19rem anular"
              />
            </div>
            {errorMessage && (
              <div
                style={{
                  color: "red",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {errorMessage}
              </div>
            )}
            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                {t("OBSERVAIONS")}
              </label>
              <InputText
                id="observacoes"
                placeholder={t("OPTIONAL")}
                value={observacao}
                className="anular2"
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
          </Dialog>

          <Dialog
            visible={procuraDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<ProcurasHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={procuraDialogFooter}
            onHide={hideProcuras}
          >
            <div className="field ">
              <label
                htmlFor="Trabalhador"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("WORKER")}
              </label>
              <Dropdown
                value={selectedTrabalhador}
                onChange={(e) => setSelectedTrabalhador(e.value)}
                options={trabalhadores}
                optionLabel="trabalhador"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Motivo"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("MOTIVE")}
              </label>
              <Dropdown
                value={selectedMotivo}
                onChange={(e) => setSelectedMotivo(e.value)}
                options={motivos}
                optionLabel="motivo"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                {t("DESCRIPTION")}
              </label>
              <InputText
                id="descricao"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                placeholder={t("OPTIONAL")}
                className=" procura-dialog w-full     md:w-19rem "
              />
            </div>

            <div className="field">
              <label
                htmlFor="dataInicio"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("BEGIN_DATE2")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                className=" procura-dialog w-full     md:w-19rem "
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
                placeholder={t("OPTIONAL")}
              />
            </div>

            <div className="field">
              <label
                htmlFor="dataFim"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("END_DATE2")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateF}
                className="   procura-dialog"
                onChange={(e) => setDateF(e.value)}
                dateFormat="dd/mm/yy"
                placeholder={t("OPTIONAL")}
              />
            </div>
            <div className="field ">
              <label
                htmlFor="Chefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("BOSS")}
              </label>
              <Dropdown
                value={selectedChefe}
                onChange={(e) => setSelectedChefe(e.value)}
                options={chefes}
                optionLabel="trabalhador"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Despacho"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("ORDER_HIERARCHY3")}
              </label>
              <Dropdown
                value={selectedDespachoHierarquia}
                onChange={(e) => setSelectedDespachoHierarquia(e.value)}
                options={despachoHierarquia}
                optionLabel="despacho"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field">
              <label htmlFor="infoHierarquia" style={{ fontSize: "0.9rem" }}>
                {t("INFO_DATE")}
              </label>
              <InputText
                id="infoHierarquia"
                value={infoHierarquia}
                onChange={(e) => setInfoHierarquia(e.target.value)}
                placeholder={t("OPTIONAL")}
                className=" procura-dialog w-full     md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="SAFP"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("SAFP")}
              </label>
              <Dropdown
                value={selectedSAFP}
                onChange={(e) => setSelectedSAFP(e.value)}
                options={safp}
                optionLabel="trabalhador"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Despacho"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("ORDER_SAFP3")}
              </label>
              <Dropdown
                value={selectedDespacho2}
                onChange={(e) => setSelectedDespacho2(e.value)}
                options={despacho2}
                optionLabel="despacho"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field">
              <label htmlFor="observacoesSAFP" style={{ fontSize: "0.9rem" }}>
                {t("SAFP_OBSERVATIONS")}
              </label>
              <InputText
                id="observacoes"
                placeholder={t("OPTIONAL")}
                value={observacao}
                className=" procura-dialog w-full     md:w-19rem "
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil"
                    label={t("ORDER_HIERARCHY3")}
                    text
                    onClick={(event) => {
                      hide(event);
                      // reject();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                      color: "#df8f34",
                    }}
                  ></Button>
                  <Button
                    label={t("YES")}
                    text
                    onClick={(event) => {
                      hide(event);
                      // accept();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default SAFPhistorico;
